<template>
  <div>
    <b-row>
      <b-col md="12">
        <validation-observer
          ref="additionaloptionRules"
          tag="form"
        >
          <b-button-toolbar justify>
            <div />
            <div>
              <b-button
                v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                @click="UpdateAddlOptions()"
              >
                <font-awesome-icon
                  :icon="['far', 'save']"
                  size="lg"
                />
              </b-button>
            </div>
          </b-button-toolbar>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Air Manifest"
                  rules="max:15"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Air Manifest:</b></i></label>
                    <b-form-input
                      id="AirManifest"
                      v-model="options.AirManifest"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Air Manifest"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="4811 Ref #"
                  rules="max:15"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>4811 Ref #:</b></i></label>
                    <b-form-input
                      id="F4811RefNumber"
                      v-model="options.F4811RefNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="4811 Ref #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="IT Number"
                  rules="max:25"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>IT #:</b></i></label>
                    <b-form-input
                      id="ITNumber"
                      v-model="options.ITNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="IT #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="G.O. #"
                  rules="max:25"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>G.O. #:</b></i></label>
                    <b-form-input
                      id="GONumber"
                      v-model="options.GONumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="G.O. #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Deferred Tax Code"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class=""><i><b>Deferred Tax Code:</b></i></label>
                    <v-select
                      id="DeferredTaxCode"
                      v-model="options.DeferredTaxCode"
                      :options="DeferralOptions"
                      label="text"
                      placeholder="Select the option"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Deferred Tax Rate"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Deferred Tax Rate:</b></i></label>
                    <b-form-input
                      id="DeferredTaxRate"
                      v-model="options.DeferredTaxRate"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="FTZ"
                  rules="max:9"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>FTZ #:</b></i></label>
                    <b-form-input
                      id="FTZCode"
                      v-model="options.FTZCode"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="FTZ #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Split Indicator"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class=""><i><b>Split Indicator:</b></i></label>
                    <v-select
                      id="SplitIndicator"
                      v-model="options.SplitIndicator"
                      :options="SplitOptions"
                      label="text"
                      placeholder="Select the option"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <label><i><b>PGA:</b></i></label>
              <b-form-checkbox v-model="options.IsPGA" />
            </b-col>
            <b-col md="2">
              <label><i><b>Consolidated Express:</b></i></label>
              <b-form-checkbox v-model="options.IsConsolidatedExpress" />
            </b-col>
            <b-col md="2">
              <label><i><b>Sec. 321:</b></i></label>
              <b-form-checkbox v-model="options.IsSec321" />
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              AD/CVD Bonds
            </div>
          </div>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bond Type"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class=""><i><b>Bond Type:</b></i></label>
                    <v-select
                      id="BondType"
                      v-model="options.ADCVDBondType"
                      :options="BondTypeOptions"
                      label="text"
                      placeholder="Select the bond type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Surety Number"
                  :rules="`${options.ADCVDBondType && (options.ADCVDBondType.value === '8' || options.ADCVDBondType.value === '9') ? 'max:3|required' : 'max:3'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${options.ADCVDBondType && (options.ADCVDBondType.value === '8' || options.ADCVDBondType.value === '9') ? 'required' : ''}`"><i><b>Surety #:</b></i></label>
                    <b-form-input
                      id="SuretyNumber"
                      v-model="options.ADCVDSurety"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Surety #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="STB Account"
                  :rules="`${options.ADCVDBondType && (options.ADCVDBondType.value === '9') ? 'required|max:10' : 'max:10'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${options.ADCVDBondType && (options.ADCVDBondType.value === '9') ? 'required' : ''}`"><i><b>STB Account #:</b></i></label>
                    <b-form-input
                      id="STBAccountNumber"
                      v-model="options.ADCVDSTBAccount"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="STB Account #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bond Amount"
                  :rules="`${options.ADCVDBondType && (options.ADCVDBondType.value === '8' || options.ADCVDBondType.value === '9') ? 'required' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${options.ADCVDBondType && (options.ADCVDBondType.value === '8' || options.ADCVDBondType.value === '9') ? 'required' : ''}`"><i><b>Bond Amount:</b></i></label>
                    <b-form-input
                      id="BondAmount"
                      v-model="options.ADCVDSTBAmount"
                      v-uppercase
                      type="number"
                      min="0"
                      max="999999999"
                      step="1"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="divider my-2">
            <div class="divider-text">
              <b-form-checkbox v-model="options.IsTARecon">
                TA Reconciliation
              </b-form-checkbox>
            </div>
          </div>
          <b-row v-if="options.IsTARecon">
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Reconciliation Issue Code"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class=""><i><b>Reconciliation Issue Code:</b></i></label>
                    <v-select
                      id="ReconType"
                      v-model="options.ReconType"
                      :options="ReconcilOptions"
                      label="text"
                      placeholder="Select the option"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Missing Doc Code"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class=""><i><b>Missing Doc Code 1:</b></i></label>
                    <v-select
                      id="MissingDoc1"
                      v-model="options.MissingDoc1"
                      :options="MissingDocOptions"
                      label="text"
                      placeholder="Select the option"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Missing Doc Code"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class=""><i><b>Missing Doc Code 2:</b></i></label>
                    <v-select
                      id="MissingDoc2"
                      v-model="options.MissingDoc2"
                      :options="MissingDocOptions"
                      label="text"
                      placeholder="Select the option"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              <b-form-checkbox v-model="options.IsPSC">
                PSC
              </b-form-checkbox>
            </div>
          </div>
          <b-row v-if="options.IsPSC">
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="PSC Code"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${options.IsPSC ? 'required' : ''}`"><i><b>PSC 1:</b></i></label>
                    <v-select
                      id="PSC1"
                      v-model="options.PSC1"
                      :options="PSCHeaderOptions"
                      label="text"
                      placeholder="Select the option"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="PSC Code"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>PSC 2:</b></i></label>
                    <v-select
                      id="PSC2"
                      v-model="options.PSC2"
                      :options="PSCHeaderOptions"
                      label="text"
                      placeholder="Select the option"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="PSC Code"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>PSC 3:</b></i></label>
                    <v-select
                      id="PSC3"
                      v-model="options.PSC3"
                      :options="PSCHeaderOptions"
                      label="text"
                      placeholder="Select the option"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="PSC Code"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>PSC 4:</b></i></label>
                    <v-select
                      id="PSC4"
                      v-model="options.PSC4"
                      :options="PSCHeaderOptions"
                      label="text"
                      placeholder="Select the option"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="PSC Code"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>PSC 5:</b></i></label>
                    <v-select
                      id="PSC5"
                      v-model="options.PSC5"
                      :options="PSCHeaderOptions"
                      label="text"
                      placeholder="Select the option"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="options.IsPSC">
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Contact Name"
                  :rules="`${options.IsPSC ? 'required|max:40' : 'max:40'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${options.IsPSC ? 'required' : ''}`"><i><b>Contact Name:</b></i></label>
                    <b-form-input
                      id="PSCContactName"
                      v-model="options.PSCContactName"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Contact Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Contact Phone"
                  :rules="`${options.IsPSC ? 'required|max:15' : 'max:15'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${options.IsPSC ? 'required' : ''}`"><i><b>Contact Phone:</b></i></label>
                    <b-form-input
                      id="PSCContactPhone"
                      v-model="options.PSCContactPhone"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Contact phone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="PSC Reason"
                  rules="max:75"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>PSC Reason:</b></i></label>
                    <b-form-input
                      id="PSCReason"
                      v-model="options.PSCReason"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="PSC Reason"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              <b-form-checkbox v-model="options.IsConsolidatedES">
                Consolidated Release
              </b-form-checkbox>
            </div>
          </div>
          <b-row v-if="options.IsConsolidatedES">
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry 1"
                  :rules="`${options.IsConsolidatedES ? 'required|max:11' : 'max:11'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${options.IsConsolidatedES ? 'required' : ''}`"><i><b>Entry 1:</b></i></label>
                    <b-form-input
                      id="ConsolidatedES"
                      v-model="options.ConsolidatedES"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Entry #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry 2"
                  rules="max:11"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Entry 2:</b></i></label>
                    <b-form-input
                      id="ConsolidatedES1"
                      v-model="options.ConsolidatedES1"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Entry #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry 3"
                  rules="max:11"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Entry 3:</b></i></label>
                    <b-form-input
                      id="ConsolidatedES2"
                      v-model="options.ConsolidatedES2"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Entry #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry 4"
                  rules="max:11"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Entry 4:</b></i></label>
                    <b-form-input
                      id="ConsolidatedES3"
                      v-model="options.ConsolidatedES3"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Entry #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry 5"
                  rules="max:11"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Entry 5:</b></i></label>
                    <b-form-input
                      id="ConsolidatedES4"
                      v-model="options.ConsolidatedES4"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Entry #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'

export default {
  name: 'EsAddlOptions',
  props: {
    esid: { type: String, default: '' },
    inace: { type: String, default: '' },
    entryaction: { type: String, default: '' },
  },
  data() {
    return {
      required,
      options: {
        ADCVDBondType: '',
        ADCVDSurety: '',
        ADCVDSTBAccount: '',
        ADCVDSTBAmount: '',
        SplitIndicator: '',
        ITNumber: '',
        GONumber: '',
        IsConsolidatedES: false,
        ConsolidatedES: '',
        ConsolidatedES1: '',
        ConsolidatedES2: '',
        ConsolidatedES3: '',
        ConsolidatedES4: '',
        IsTARecon: false,
        ReconType: '',
        IsPSC: false,
        PSC1: '',
        PSC2: '',
        PSC3: '',
        PSC4: '',
        PSC5: '',
        PSCContactName: '',
        PSCContactPhone: '',
        PSCReason: '',
        MissingDoc1: '',
        MissingDoc2: '',
        IsPGA: false,
        IsConsolidatedExpress: false,
        IsSec321: false,
        AirManifest: '',
        FTZCode: '',
        F4811RefNumber: '',
        DeferredTaxCode: '',
        DeferredTaxRate: 0.00,
        InformalType: '',
      },
      DeferralOptions: [
        { text: 'Conventional (1)', value: '1' },
        { text: 'EFT (2)', value: '2' },
      ],
      SplitOptions: [
        { text: 'Hold All (1)', value: '1' },
        { text: 'Incrmental (2)', value: '2' },
      ],
      ReconcilOptions: [
        { text: 'Value (001)', value: '001' },
        { text: 'Classification (002)', value: '002' },
        { text: 'Chapter 9802 (003)', value: '003' },
        { text: 'Value & Classification (004)', value: '004' },
        { text: 'Value and Chapter 9802 (005)', value: '005' },
        { text: 'Classification & Chapter 9802 (006)', value: '006' },
        { text: 'Value, Classification & Chapter 9802 (007)', value: '007' },
      ],
      MissingDocOptions: [
        { text: 'Commercial Invoice (01)', value: '01' },
        { text: 'CBPF 5523 (10)', value: '10' },
        { text: 'CBPF3291 (11)', value: '11' },
        { text: 'Lease Statement (14)', value: '14' },
        { text: 'Re-melting Certificate (15)', value: '15' },
        { text: 'Corrected Commercial Invoice (16)', value: '16' },
        { text: 'Other Agency Forms (17)', value: '17' },
        { text: 'Duty Free Entry Certificate (18)', value: '18' },
        { text: 'Scale Weight (19)', value: '19' },
        { text: 'End Use Certificate (20)', value: '20' },
        { text: 'Form O (Coffee) (21)', value: '21' },
        { text: 'Chemical Analysis (22)', value: '22' },
        { text: 'Out Turn Report (23)', value: '23' },
        { text: 'Packing List (26)', value: '26' },
        { text: 'Other document (98)', value: '98' },
        { text: 'More than two documents listed (99)', value: '99' },
      ],
      PSCHeaderOptions: [
        { text: 'Entry Type Non-AD/CVD-to-AD/CVD (H01)', value: 'H01' },
        { text: 'Entry Type-Other (H02)', value: 'H02' },
        { text: 'Mode of Transportation (H04)', value: 'H04' },
        { text: 'Bond-Surety (H05)', value: 'H05' },
        { text: 'Bond–Superseding (H06)', value: 'H06' },
        { text: 'Bond-Other (H07)', value: 'H07' },
        { text: 'Electronic Invoice Availability (H08)', value: 'H08' },
        { text: 'Shipment Usage Type (H09)', value: 'H09' },
        { text: 'Consignee (H10)', value: 'H10' },
        { text: 'Date of Importation (H11)', value: 'H11' },
        { text: 'State of Destination (H12)', value: 'H12' },
        { text: 'Header Fee (H13)', value: 'H13' },
        { text: 'Entry Type AD/CVD-to-Non-AD/CVD (H14)', value: 'H14' },
        { text: 'Data Change–Other (H99)', value: 'H99' },
      ],
      BondTypeOptions: [
        { text: 'Continuous (8)', value: '8' },
        { text: 'STB (9)', value: '9' },

      ],
    }
  },
  mounted() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadAddlOptions()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadAddlOptions() {
      axios.get(`/entry/${this.esid}/summary/options`).then(resp => {
        const response = resp.data
        const respdata = response[0]
        this.options.SplitIndicator = this.SplitOptions.find(option => option.value === respdata.splitIndicator)
        this.options.ITNumber = respdata.itNumber
        this.options.GONumber = respdata.goNumber
        this.options.IsConsolidatedES = respdata.isConsolidatedES
        this.options.ConsolidatedES = respdata.consolidatedES
        this.options.ConsolidatedES1 = respdata.consolidatedES1
        this.options.ConsolidatedES2 = respdata.consolidatedES2
        this.options.ConsolidatedES3 = respdata.consolidatedES3
        this.options.ConsolidatedES4 = respdata.consolidatedES4
        this.options.IsTARecon = respdata.isTARecon
        this.options.ReconType = this.ReconcilOptions.find(option => option.value === respdata.reconType)
        this.options.IsPSC = respdata.isPSC
        this.options.PSC1 = this.PSCHeaderOptions.find(option => option.value === respdata.psC1)
        this.options.PSC2 = this.PSCHeaderOptions.find(option => option.value === respdata.psC2)
        this.options.PSC3 = this.PSCHeaderOptions.find(option => option.value === respdata.psC3)
        this.options.PSC4 = this.PSCHeaderOptions.find(option => option.value === respdata.psC4)
        this.options.PSC5 = this.PSCHeaderOptions.find(option => option.value === respdata.psC5)
        this.options.PSCContactName = respdata.pscContactName
        this.options.PSCContactPhone = respdata.pscContactPhone
        this.options.PSCReason = respdata.pscReason
        this.options.MissingDoc1 = this.MissingDocOptions.find(option => option.value === respdata.missingDoc1)
        this.options.MissingDoc2 = this.MissingDocOptions.find(option => option.value === respdata.missingDoc2)
        this.options.IsPGA = respdata.isPGA
        this.options.IsConsolidatedExpress = respdata.isConsolidatedExpress
        this.options.IsSec321 = respdata.isSec321
        this.options.AirManifest = respdata.airManifest
        this.options.FTZCode = respdata.ftzCode
        this.options.F4811RefNumber = respdata.f4811RefNumber
        this.options.DeferredTaxCode = this.DeferralOptions.find(option => option.value === respdata.deferredTaxCode)
        this.options.ADCVDBondType = this.BondTypeOptions.find(option => option.value === respdata.adcvdBondType)
        this.options.ADCVDSurety = respdata.adcvdSurety
        this.options.ADCVDSTBAccount = respdata.adcvdstbAccount
        this.options.ADCVDSTBAmount = respdata.adcvdstbAmount
      })
    },
    UpdateAddlOptions() {
      this.$refs.additionaloptionRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ESAddlOptionsModel = {
            ADCVDSurety: this.options.ADCVDSurety,
            ADCVDBondType: this.CheckNull(this.options.ADCVDBondType) ? this.options.ADCVDBondType.value : '',
            ADCVDSTBAccount: this.options.ADCVDSTBAccount,
            ADCVDSTBAmount: this.CheckNull(this.options.ADCVDSTBAmount) ? this.options.ADCVDSTBAmount : 0,
            SplitIndicator: this.CheckNull(this.options.SplitIndicator) ? this.options.SplitIndicator.value : '',
            ITNumber: this.options.ITNumber,
            GONumber: this.options.GONumber,
            IsConsolidatedES: this.options.IsConsolidatedES,
            ConsolidatedES: this.options.ConsolidatedES,
            ConsolidatedES1: this.options.ConsolidatedES1,
            ConsolidatedES2: this.options.ConsolidatedES2,
            ConsolidatedES3: this.options.ConsolidatedES3,
            ConsolidatedES4: this.options.ConsolidatedES4,
            IsTARecon: this.options.IsTARecon,
            ReconType: this.CheckNull(this.options.ReconType) ? this.options.ReconType.value : '',
            IsPSC: this.options.IsPSC,
            PSC1: this.CheckNull(this.options.PSC1) ? this.options.PSC1.value : '',
            PSC2: this.CheckNull(this.options.PSC2) ? this.options.PSC2.value : '',
            PSC3: this.CheckNull(this.options.PSC3) ? this.options.PSC3.value : '',
            PSC4: this.CheckNull(this.options.PSC4) ? this.options.PSC4.value : '',
            PSC5: this.CheckNull(this.options.PSC5) ? this.options.PSC5.value : '',
            PSCContactName: this.options.PSCContactName,
            PSCContactPhone: this.options.PSCContactPhone,
            PSCReason: this.options.PSCReason,
            MissingDoc1: this.CheckNull(this.options.MissingDoc1) ? this.options.MissingDoc1.value : '',
            MissingDoc2: this.CheckNull(this.options.MissingDoc2) ? this.options.MissingDoc2.value : '',
            IsPGA: this.options.IsPGA,
            IsConsolidatedExpress: this.options.IsConsolidatedExpress,
            IsSec321: this.options.IsSec321,
            AirManifest: this.options.AirManifest,
            FTZCode: this.options.FTZCode,
            F4811RefNumber: this.options.F4811RefNumber,
            DeferredTaxCode: this.CheckNull(this.options.DeferredTaxCode) ? this.options.DeferredTaxCode.value : '',
            DeferredTaxRate: this.CheckNull(this.options.DeferredTaxRate) ? this.options.DeferredTaxRate : 0.00,

          }
          axios.put(`/entry/${this.esid}/summary/options`, ESAddlOptionsModel).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Additional Options data has been saved.')
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Additional Options data cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        } else {
          this.DisplayError('Additional Options data cannot be saved.', 'Please check data requirements.')
        }
      })
    },
  },
}
</script>
