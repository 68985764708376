<template>
  <div>
    <b-row>
      <b-col md="12">
        <validation-observer
          ref="headerRules"
          tag="form"
        >
          <b-row>
            <b-col
              md="12"
              style="margin: auto"
            >
              <b-form-checkbox v-model="header.IsActive">
                Active
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-button-toolbar justify>
            <div>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry Action"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Entry Action:</b></i></label>
                    <v-select
                      id="EntryActionOption"
                      v-model="header.EntryAction"
                      :options="EntryActionOptions"
                      label="text"
                      placeholder="Select the action"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </div>
            <div>
              <b-button
                v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                @click="UpdateHeader()"
              >
                <font-awesome-icon
                  :icon="['far', 'save']"
                  size="lg"
                />
              </b-button>
            </div>
          </b-button-toolbar>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Customer"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Customer:</b></i></label>
                    <v-select
                      id="CustomerOption"
                      v-model="header.CustomerId"
                      :options="CustomerOptions"
                      label="name"
                      placeholder="Select a Customer"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry Type"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Entry Type:</b></i></label>
                    <v-select
                      id="EntryTypeOption"
                      v-model="header.EntryType"
                      :options="EntryTypeOptions"
                      label="text"
                      placeholder="Select a Type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Shipment Type"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Shipment Type:</b></i></label>
                    <v-select
                      id="ShipmentTypeOption"
                      v-model="header.ShipmentType"
                      :options="ShipmentTypeOptions"
                      label="text"
                      placeholder="Select the shipment type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="MOT"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>MOT:</b></i></label>
                    <v-select
                      id="MOTOption"
                      v-model="header.MOTType"
                      :options="MOTOptions"
                      label="text"
                      placeholder="Select the MOT"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Reference #"
                  rules="required|max:20"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Reference #:</b></i></label>
                    <b-form-input
                      id="ReferenceNumber"
                      v-model="header.ReferenceNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Reference #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Broker Reference"
                  rules="max:9"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Broker Reference:</b></i></label>
                    <b-form-input
                      id="BrokerReference"
                      v-model="header.BrokerReference"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Broker Reference"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Goods Description"
                  rules="required|max:40"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Goods Description:</b></i></label>
                    <b-form-input
                      id="Description"
                      v-model="header.Description"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Goods Description"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Country of Origin"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Country of Origin:</b></i></label>
                    <v-select
                      id="COO"
                      v-model="header.COO"
                      :options="CountryOptions"
                      label="country"
                      placeholder="Select the country"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Export Country:"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Export Country:</b></i></label>
                    <v-select
                      id="ExportCountry"
                      v-model="header.ExportCountry"
                      :options="CountryOptions"
                      label="country"
                      placeholder="Select the country"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              Parties
            </div>
          </div>
          <small>Only active and in-ACE party will be shown</small>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Importer"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Importer:</b></i></label> &nbsp;<small @click="LoadAddress('importer')"><u>View Address</u></small>
                    <v-select
                      id="ImporterId"
                      v-model="header.ImporterId"
                      :options="ImporterOptions"
                      label="name"
                      placeholder="Select the importer"
                      @input="onChangeImporter"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label><i><b>EIN/MID/CBP Assigned:</b></i></label>
                <b-form-input
                  id="ImporterIdentifier"
                  v-model="header.ImporterIdentifier"
                  v-uppercase
                  readonly
                  placeholder="EIN/MID/CBP Assigned"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Consignee"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Consignee:</b></i></label> &nbsp;<small @click="LoadAddress('consignee')"><u>View Address</u></small>
                    <v-select
                      id="ConsigneeId"
                      v-model="header.ConsigneeId"
                      :options="ConsigneeOptions"
                      label="name"
                      placeholder="Select the consignee"
                      @input="onChangeConsignee"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label><i><b>EIN/MID/CBP Assigned:</b></i></label>
                <b-form-input
                  id="ConsigneeIdentifier"
                  v-model="header.ConsigneeIdentifier"
                  v-uppercase
                  readonly
                  placeholder="EIN/MID/CBP Assigned"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Ship To"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Ship To:</b></i></label> &nbsp;<small @click="LoadAddress('shipto')"><u>View Address</u></small>
                    <v-select
                      id="ShipToId"
                      v-model="header.ShipToId"
                      :options="ShipToOptions"
                      label="name"
                      placeholder="Select the Ship To"
                      @input="onChangeShipTo"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label><i><b>EIN/MID/CBP Assigned:</b></i></label>
                <b-form-input
                  id="ShipToIdentifier"
                  v-model="header.ShipToIdentifier"
                  v-uppercase
                  readonly
                  placeholder="EIN/MID/CBP Assigned"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              Bonds
            </div>
          </div>
          <b-row>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bond Type"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Bond Type:</b></i></label>
                    <v-select
                      id="BondType"
                      v-model="header.BondType"
                      :options="BondTypeOptions"
                      label="text"
                      placeholder="Select the bond type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Surety Number"
                  :rules="`${header.BondType && (header.BondType.value === '8' || header.BondType.value === '9') ? 'max:3|required' : 'max:3'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${header.BondType && (header.BondType.value === '8' || header.BondType.value === '9') ? 'required' : ''}`"><i><b>Surety #:</b></i></label>
                    <b-form-input
                      id="SuretyNumber"
                      v-model="header.SuretyNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Surety #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bond #"
                  :rules="`${header.BondType && (header.BondType.value === '8') ? 'max:15|required' : 'max:15'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${header.BondType && (header.BondType.value === '8') ? 'required' : ''}`"><i><b>Continuous Bond #:</b></i></label>
                    <b-form-input
                      id="BondNumber"
                      v-model="header.BondNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Continuous Bond #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="STB Account"
                  :rules="`${header.BondType && (header.BondType.value === '9') ? 'required|max:10' : 'max:10'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${header.BondType && (header.BondType.value === '9') ? 'required' : ''}`"><i><b>STB Account #:</b></i></label>
                    <b-form-input
                      id="STBAccountNumber"
                      v-model="header.STBAccountNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="STB Account #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bond Amount"
                  :rules="`${header.BondType && (header.BondType.value === '8' || header.BondType.value === '9') ? 'required' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${header.BondType && (header.BondType.value === '8' || header.BondType.value === '9') ? 'required' : ''}`"><i><b>Bond Amount:</b></i></label>
                    <b-form-input
                      id="BondAmount"
                      v-model="header.BondAmount"
                      v-uppercase
                      type="number"
                      min="0"
                      max="999999999"
                      step="1"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bond Waiver"
                  :rules="`${header.BondType && (header.BondType.value === '0') ? 'required' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${header.BondType && (header.BondType.value === '0') ? 'required' : ''}`"><i><b>Bond Waiver:</b></i></label>
                    <v-select
                      id="BondWaiver"
                      v-model="header.BondWaiver"
                      :options="BondWaiverOptions"
                      label="text"
                      placeholder="Select the bond waiver"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              <b-form-checkbox v-model="header.IsACHDirect">
                ACH Direct
              </b-form-checkbox>
            </div>
          </div>
          <b-row>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="ACH Number"
                  :rules="`${header.IsACHDirect ? 'max:15|required' : 'max:15'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>ACH Number:</b></i></label>
                    <b-form-input
                      id="ACHNumber"
                      v-model="header.ACHNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="ACH Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Statement Type"
                  :rules="`${header.IsACHDirect ? 'required' : 'required'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Statement Type:</b></i></label>
                    <v-select
                      id="StatementType"
                      v-model="header.StatementType"
                      :options="StatementTypeOptions"
                      label="text"
                      placeholder="Select a statement type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Statement Month"
                  :rules="`${header.IsACHDirect ? 'required' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Statement Month:</b></i></label>
                    <b-form-input
                      id="StatementMonth"
                      v-model="header.StatementMonth"
                      v-uppercase
                      type="number"
                      min="1"
                      max="12"
                      step="1"
                      :state="errors.length > 0 ? false:null"
                      placeholder="1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <div class="divider my-2">
            <div class="divider-text">
              Options
            </div>
          </div>
          <b-row>
            <b-col md="4">
              <b-form-checkbox v-model="header.IsRemote">
                Remote
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox v-model="header.IsLiveEntry">
                Live
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox v-model="header.IsKnownImporter">
                Known Importer
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-checkbox v-model="header.IsCargoReleaseCertify">
                Cargo Release
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox v-model="header.IsEIP">
                EIP
              </b-form-checkbox>
            </b-col>
            <b-col md="4">
              <b-form-checkbox v-model="header.IsTIB">
                TIB
              </b-form-checkbox>&nbsp;&nbsp;<small @click="OpenTIBResponse()"><u>View Status</u></small>
            </b-col>
          </b-row>
          <b-button-toolbar
            v-if="header.IsTIB"
            justify
          >
            <div />
            <div>
              <b-button
                v-b-tooltip.hover="'TIB Extension'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="my-1"
                @click="SendTIBExten()"
              ><feather-icon
                 icon="SendIcon"
                 class="mr-50"
               />
                <span class="align-middle">TIB Extension</span></b-button>&nbsp;
              <b-button
                v-b-tooltip.hover="'TIB Closure'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="my-1"
                @click="SendTIBClose()"
              ><feather-icon
                 icon="SendIcon"
                 class="mr-50"
               />
                <span class="align-middle">TIB Closure</span></b-button>
            </div>
          </b-button-toolbar>
        </validation-observer>
        <div class="divider my-2">
          <div class="divider-text">
            {{ 'Last Updated: ' + header.LastUpdatedOn + ' by ' + header.LastUpdatedBy }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="TIBStatusModal"
      ref="TIBStatus"
      title="TIB Status"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <label :style="{color:color}">{{ FinalDispositionCode + ' / ' + FinalDispositionDescription }}</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button-toolbar
            v-if="header.IsTIB"
            justify
          >
            <div />
            <div>
              <b-button
                v-b-tooltip.hover="'Refresh notification'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                @click="RefreshTIB()"
              >
                <font-awesome-icon
                  :icon="['fas', 'sync']"
                  size="lg"
                />
              </b-button>
            </div>
          </b-button-toolbar>
          <b-table
            v-if="ResponseTable"
            :fields="fields"
            :items="ResponseTable"
            responsive=""
            hover
          />
        </b-col>
      </b-row>
      <template #modal-footer>
        <div /></template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'

export default {
  name: 'EsHeader',
  props: {
    esid: { type: String, default: '' },
  },
  data() {
    return {
      fields: [
        { key: 'blockDispositionCode', lable: 'Disposition Code' },
        { key: 'conditionCode', lable: 'Condition Code' },
        { key: 'message', lable: 'Message' },
        { key: 'addedDate', lable: 'Response Date' },
      ],
      ResponseTable: [],
      FinalDispositionCode: '',
      FinalDispositionDescription: '',
      color: '',
      required,
      CustomerOptions: [],
      ImporterOptions: [],
      ConsigneeOptions: [],
      ShipToOptions: [],
      CountryOptions: [],
      EntryActionOptions: [
        { text: 'Action: Add', value: 'A' },
        { text: 'Action: Replace', value: 'R' },

      ],
      EntryTypeOptions: [
        { text: 'Free and Dutiable (01)', value: '01' },
        { text: 'Quota/Visa (02)', value: '02' },
        { text: 'AD/CVD (03)', value: '03' },
        { text: 'Appraisement (04)', value: '04' },
        { text: 'Vessel Repair (05)', value: '05' },
        { text: 'FTZ Consumption (06)', value: '06' },
        { text: 'Quota/Visa & AD/CVD (07)', value: '07' },
        { text: 'Duty Deferrals (08)', value: '08' },
        { text: 'Free and Dutiable (11)', value: '11' },
        { text: 'Quota Other Than Textiles (12)', value: '12' },
        { text: 'Warehouse (21)', value: '21' },
        { text: 'Re-Warehouse (22)', value: '22' },
        { text: 'TIB (23)', value: '23' },
        { text: 'Trade Fair (24)', value: '24' },
        { text: 'Permanent Exhibition (25)', value: '25' },
        { text: 'FTZ Admission (26)', value: '26' },
        { text: 'Consumption (31)', value: '31' },
        { text: 'Quota/Visa (32)', value: '32' },
        { text: 'AD/CVD (34)', value: '34' },
        { text: 'Quota/Visa & AD/CVD (38)', value: '38' },
        { text: 'DCMAO (51)', value: '51' },
        { text: 'Other Agency (52)', value: '52' },
        { text: 'I.T. (61)', value: '61' },
        { text: 'Transportation & Exportation (62)', value: '62' },
        { text: 'I.E. (63)', value: '63' },
      ],
      MOTOptions: [
        { text: 'Vessel, Non-container (10)', value: '10' },
        { text: 'Vessel, Container (11)', value: '11' },
        { text: 'Border, Waterborne (12)', value: '12' },
        { text: 'Rail, Non-container (20)', value: '20' },
        { text: 'Rail, Container (21)', value: '21' },
        { text: 'Truck, Non-Container (30)', value: '30' },
        { text: 'Truck, Container (31)', value: '31' },
        { text: 'Auto (32)', value: '32' },
        { text: 'Pedestrain (33)', value: '33' },
        { text: 'Road, other (34)', value: '34' },
        { text: 'Air, Non-container (40)', value: '40' },
        { text: 'Air, Container (41)', value: '41' },
        { text: 'Mail (50)', value: '50' },
        { text: 'Passenger, hand-carried (60)', value: '60' },
        { text: 'Fiex Transport (70)', value: '70' },
      ],
      BondTypeOptions: [
        { text: 'Not required/Gov', value: '0' },
        { text: 'Continuous (8)', value: '8' },
        { text: 'STB (9)', value: '9' },

      ],
      BondWaiverOptions: [
        { text: 'None', value: '0' },
        { text: 'Supplemental Duty (995)', value: '995' },
        { text: 'Fines & Penalties (996)', value: '996' },
        { text: 'All other (997)', value: '997' },
        { text: 'Bill Secured (998)', value: '998' },
        { text: 'No Surety (999)', value: '999' },
      ],
      ShipmentTypeOptions: [
        { text: 'Personal', value: '0' },
        { text: 'Commercial', value: '2' },
        { text: 'Sample', value: '1' },

      ],
      StatementTypeOptions: [
        { text: 'None/Cash', value: '1' },
        { text: 'Daily Broker Stmt.', value: '2' },
        { text: 'Daily Importer Stmt. w/ suff,', value: '3' },
        { text: 'Daily Importer Stmt. w/o suff.', value: '5' },
        { text: 'Broker Stmt.', value: '6' },
        { text: 'Importer Stmt. w/ suff.', value: '7' },
        { text: 'Importer Stmt. w/ suff.', value: '8' },
      ],
      header: {
        CustomerId: '',
        InACE: false,
        InACEDelete: false,
        IsActive: true,
        Status: '',
        Revision: '',
        EntryAction: null,
        EntryFiler: '',
        EntryNumber: '',
        EntryType: null,
        MOTType: null,
        ShipmentType: null,
        ReferenceNumber: '',
        BrokerReference: '',
        Description: '',
        COO: null,
        ExportCountry: null,
        MAddress: '',
        PAddress: '',
        ImporterId: null,
        ImporterIdentifier: '',
        ConsigneeId: null,
        ConsigneeIdentifier: '',
        ShipToId: null,
        ShipToIdentifier: '',
        SuretyNumber: '',
        STBAccountNumber: '',
        BondType: null,
        BondNumber: '',
        BondAmount: 0,
        BondWaiver: null,
        StatementType: null,
        StatementMonth: '',
        ACHNumber: '',
        IsRemote: false,
        IsCargoReleaseCertify: false,
        IsEIP: false,
        IsLiveEntry: false,
        IsKnownImporter: false,
        IsTIB: false,
        IsACHDirect: false,
        LastUpdatedOn: '',
        LastUpdatedBy: '',
      },
      temp: {
        ImporterId: '',
        ConsigneeId: '',
        ShipToId: '',

      },
    }
  },
  computed: {
    IsBondWaived() {
      if (this.header.BondType && this.header.BondType.value === '0') {
        return {
          disabled: false,
        }
      }
      return {
        disabled: true,
      }
    },
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  methods: {
    EmitData() {
      const es = {
        CustomerId: this.header.CustomerId,
        FilerCode: this.header.EntryFiler,
        EntryNumber: this.header.EntryNumber,
        Revision: this.header.Revision,
        InACE: this.header.InACE,
        IsActive: this.header.IsActive,
        MOT: this.header.MOTType.value,
        ET: this.header.EntryType.value,
        EntryAction: this.header.EntryAction,
        Status: this.header.Status,
      }
      this.$emit('getFromDetail', es)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadCustomerList(), this.LoadCountryList(), this.LoadResponse()]).then(() => {
        this.LoadHeaderData().then(() => {
          // Load header ==> load parties
          // this.EmitData()
          Promise.all([this.LoadImporterList(), this.LoadConsigneeList(), this.LoadShipToList()]).then(() => {
            // Load party data
            this.header.ImporterId = this.ImporterOptions.find(option => option.partyId === this.temp.ImporterId)
            // eslint-disable-next-line no-nested-ternary
            this.header.ImporterIdentifier = this.CheckNull(this.header.ImporterId) ? this.header.ImporterId.ein === '' ? this.header.ImporterId.cbpAssigned : this.header.ImporterId.ein : ''
            this.header.ConsigneeId = this.ConsigneeOptions.find(option => option.partyId === this.temp.ConsigneeId)
            // eslint-disable-next-line no-nested-ternary
            this.header.ConsigneeIdentifier = this.CheckNull(this.header.ConsigneeId) ? this.header.ConsigneeId.ein === '' ? this.header.ConsigneeId.cbpAssigned : this.header.ConsigneeId.ein : ''
            this.header.ShipToId = this.ShipToOptions.find(option => option.partyId === this.temp.ShipToId)
            // eslint-disable-next-line no-nested-ternary
            this.header.ShipToIdentifier = this.CheckNull(this.header.ShipToId) ? (this.header.ShipToId.ein === '' ? this.header.ShipToId.cbpAssigned : this.header.ShipToId.ein) : ''

            this.LoadingEnd()
          })
        })
      }).then(() => {
        this.LoadingEnd()
      })
    },
    LoadResponse() {
      return axios.get(`/entry/${this.esid}/tib/response`)
        .then(resp => {
          if (resp.data[0] != null && resp.data[0]) {
            this.ResponseTable = resp.data
            this.FinalDispositionCode = resp.data[0].finalDispositionCode
            this.FinalDispositionDescription = `${resp.data[0].finalMessage} (${resp.data[0].finalConditionCode})`
            // eslint-disable-next-line no-nested-ternary
            this.color = resp.data[0].finalDispositionCode === 'RF' ? 'red' : resp.data[0].finalDispositionCode === 'A' ? 'green' : 'darkorange'
          } else {
            this.FinalDispositionCode = 'Pending'
            this.FinalDispositionDescription = 'No Response'
          }
        })
    },
    OpenTIBResponse() {
      this.$refs.TIBStatus.show()
    },
    LoadCustomerList() {
      // Loading Customer List
      return axios.get('/abi/customer/codes', {
        params: {
          UserId: this.$store.state.user.AppActiveUser.Id,
          DBContext: (this.$store.state.user.AppActiveUser.ABIDbContext),
        },
      })
        .then(rowData => {
          this.CustomerOptions = rowData.data
        })
    },
    LoadCountryList() {
      // Loading Country List
      return axios.get('/abi/codes/country')
        .then(rowData => {
          this.CountryOptions = rowData.data
        })
    },
    LoadImporterList() {
      // Loading Customer List
      return axios.get(`/abi/party/${this.header.CustomerId.id}/importer`)
        .then(rowData => {
          this.ImporterOptions = rowData.data
        })
    },
    LoadConsigneeList() {
      // Loading Customer List
      return axios.get(`/abi/party/${this.header.CustomerId.id}/consignee`)
        .then(rowData => {
          this.ConsigneeOptions = rowData.data
        })
    },
    LoadShipToList() {
      // Loading Customer List
      return axios.get(`/abi/party/${this.header.CustomerId.id}/shipto`)
        .then(rowData => {
          this.ShipToOptions = rowData.data
        })
    },
    LoadHeaderData() {
      //
      return axios.get(`/entry/${this.esid}/summary/header`)
        .then(resp => {
          const response = resp.data
          const respdata = response[0]
          this.header.CustomerId = this.CustomerOptions.find(option => option.id === respdata.customerId)
          this.header.InACE = respdata.inACE
          this.header.IsActive = respdata.active
          this.header.Status = respdata.status
          this.header.Revision = respdata.revision
          this.header.EntryAction = this.EntryActionOptions.find(option => option.value === respdata.entryAction) //
          this.header.EntryFiler = respdata.entryFiler
          this.header.EntryNumber = respdata.entryNumberWithCheckdigit
          this.header.EntryType = this.EntryTypeOptions.find(option => option.value === respdata.entryType)
          this.header.MOTType = this.MOTOptions.find(option => option.value === respdata.mot)
          this.header.ShipmentType = this.ShipmentTypeOptions.find(option => option.value === respdata.shipmentType)
          this.header.ReferenceNumber = respdata.referenceNumber
          this.header.BrokerReference = respdata.brokerReference
          this.header.Description = respdata.description
          this.header.COO = this.CountryOptions.find(option => option.countryCode === respdata.coo)
          this.header.ExportCountry = this.CountryOptions.find(option => option.countryCode === respdata.exportCountry)

          this.temp.ImporterId = respdata.importer
          this.temp.ConsigneeId = respdata.consignee
          this.temp.ShipToId = respdata.shipTo

          this.header.SuretyNumber = respdata.suretyNumber
          this.header.BondType = this.BondTypeOptions.find(option => option.value === respdata.bondType)
          this.header.BondNumber = respdata.bondNumber
          this.header.BondAmount = respdata.bondAmount
          this.header.STBAccountNumber = respdata.stbAccountNumber
          this.header.BondWaiver = this.BondWaiverOptions.find(option => option.value === respdata.bondWaiverCode)
          this.header.StatementType = this.StatementTypeOptions.find(option => option.value === respdata.statementType)
          const today = new Date()
          const month = today.getMonth() + 2
          this.header.StatementMonth = respdata.statementMonth === '0' ? month : respdata.statementMonth
          this.header.ACHNumber = respdata.achNumber
          this.header.IsRemote = respdata.isRemote
          this.header.IsCargoReleaseCertify = respdata.isCargoReleaseCertify
          this.header.IsEIP = respdata.isEIP
          this.header.IsLiveEntry = respdata.isLiveEntry
          this.header.IsKnownImporter = respdata.isKnownImporter
          this.header.IsTIB = respdata.isTIB
          this.header.IsACHDirect = respdata.isACHDirect
          this.header.LastUpdatedBy = respdata.lastUpdatedBy
          this.header.LastUpdatedOn = respdata.lastUpdatedOn

          this.header.InACEDelete = respdata.inACEDelete
        })
    },
    LoadAddress(type) {
      let mailing
      let physical
      // eslint-disable-next-line default-case
      switch (type) {
        case 'importer':
          this.header.MAddress = this.CheckNull(this.header.ImporterId) ? this.header.ImporterId.mailingAddress : ''
          this.header.PAddress = this.CheckNull(this.header.ImporterId) ? this.header.ImporterId.physicalAddress : ''

          mailing = this.header.MAddress.split('`')
          physical = this.header.PAddress.split('`')

          break
        case 'consignee':
          this.header.MAddress = this.CheckNull(this.header.ConsigneeId) ? this.header.ConsigneeId.mailingAddress : ''
          this.header.PAddress = this.CheckNull(this.header.ConsigneeId) ? this.header.ConsigneeId.physicalAddress : ''

          mailing = this.header.MAddress.split('`')
          physical = this.header.PAddress.split('`')
          break
        case 'shipto':
          this.header.MAddress = this.CheckNull(this.header.ShipToId) ? this.header.ShipToId.mailingAddress : ''
          this.header.PAddress = this.CheckNull(this.header.ShipToId) ? this.header.ShipToId.physicalAddress : ''

          mailing = this.header.MAddress.split('`')
          physical = this.header.PAddress.split('`')
          break
      }
      this.$swal({
        title: 'View Address',
        html: `
    <table border class="w-full">
      <tr>
        <td colspan="4"><i><b>Mailing Address:</b></i></td>
      </tr>
      <tr>
        <td colspan="2"><u>Address 1:</u></td>
        <td colspan="2">${mailing[0]}</td>
      </tr>
      <tr>
        <td colspan="2"><u>Address 2:</u></td>
        <td colspan="2">${mailing[1]}</td>
      </tr>
      <tr>
        <td><u>City:</u></td>
        <td>${mailing[2]}</td>
        <td><u>State:</u></td>
        <td>${mailing[3]}</td>
      </tr>
      <tr>
        <td><u>Contury:</u></td>
        <td>${mailing[4]}</td>
        <td><u>Zip:</u></td>
        <td>${mailing[5]}</td>
      </tr>
      <tr>
        <td colspan="4"><i><b>Physical Address:</b></i></td>
      </tr>
      <tr>
        <td colspan="2"><u>Address 1:</u></td>
        <td colspan="2">${physical[0]}</td>
      </tr>
      <tr>
        <td colspan="2"><u>Address 2:</u></td>
        <td colspan="2">${physical[1]}</td>
      </tr>
      <tr>
        <td><u>City:</u></td>
        <td>${physical[2]}</td>
        <td><u>State:</u></td>
        <td>${physical[3]}</td>
      </tr>
      <tr>
        <td><u>Contury:</u></td>
        <td>${physical[4]}</td>
        <td><u>Zip:</u></td>
        <td>${physical[5]}</td>
      </tr>
    </table>`,
      })
    },
    onChangeImporter(option) {
      if (this.CheckNull(option)) {
        this.header.MAddress = option.mailingAddress
        this.header.PAddress = option.physicalAddress
        this.header.ImporterIdentifier = (option.ein === '' ? option.cbpAssigned : option.ein)
      } else {
        this.header.MAddress = ''
        this.header.PAddress = ''
        this.header.ImporterIdentifier = ''
      }
    },
    onChangeConsignee(option) {
      if (this.CheckNull(option)) {
        this.header.MAddress = option.mailingAddress
        this.header.PAddress = option.physicalAddress
        this.header.ConsigneeIdentifier = (option.ein === '' ? option.cbpAssigned : option.ein)
      } else {
        this.header.MAddress = ''
        this.header.PAddress = ''
        this.header.ConsigneeIdentifier = ''
      }
    },
    onChangeShipTo(option) {
      if (this.CheckNull(option)) {
        this.header.MAddress = option.mailingAddress
        this.header.PAddress = option.physicalAddress
        this.header.ShipToIdentifier = (option.ein === '' ? option.cbpAssigned : option.ein)
      } else {
        this.header.MAddress = ''
        this.header.PAddress = ''
        this.header.ShipToIdentifier = ''
      }
    },
    UpdateHeader() {
      this.$refs.headerRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ESHeaderModel = {
            CustomerId: this.header.CustomerId.id,
            Active: this.header.IsActive,
            EntryAction: this.header.EntryAction.value,
            EntryType: this.header.EntryType.value,
            MOT: this.header.MOTType.value,
            ShipmentType: this.header.ShipmentType.value,
            ReferenceNumber: this.header.ReferenceNumber,
            BrokerReference: this.header.BrokerReference,
            Description: this.header.Description,
            COO: this.header.COO.countryCode,
            ExportCountry: this.header.ExportCountry.countryCode,
            Importer: this.header.ImporterId.partyId,
            Consignee: this.header.ConsigneeId.partyId,
            ShipTo: this.CheckNull(this.header.ShipToId) ? this.header.ShipToId.partyId : '',
            SuretyNumber: this.header.SuretyNumber,
            BondType: this.CheckNull(this.header.BondType) ? this.header.BondType.value : '',
            BondNumber: this.header.BondNumber,
            BondAmount: this.CheckNull(this.header.BondAmount) ? this.header.BondAmount : 0,
            STBAccountNumber: this.header.STBAccountNumber,
            BondWaiverCode: this.CheckNull(this.header.BondWaiver) ? this.header.BondWaiver.value : '',
            StatementType: this.CheckNull(this.header.StatementType) ? this.header.StatementType.value : '',
            StatementMonth: this.header.StatementMonth,
            ACHNumber: this.header.ACHNumber,
            IsRemote: this.header.IsRemote,
            IsCargoReleaseCertify: this.header.IsCargoReleaseCertify,
            IsEIP: this.header.IsEIP,
            IsLiveEntry: this.header.IsLiveEntry,
            IsKnownImporter: this.header.IsKnownImporter,
            IsTIB: this.header.IsTIB,
            IsACHDirect: this.header.IsACHDirect,

          }
          axios.put(`/entry/${this.esid}/summary/header`, ESHeaderModel).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Header data has been saved.')
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Header data cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        } else {
          this.DisplayError('Header data cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    RefreshTIB() {
      this.LoadResponse()
    },
    SendTIBExten() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Send it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.post(`/entry/${this.esid}/tib/extend`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('TIB Extension has been Sent.')
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Cannot send TIB Extension.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    SendTIBClose() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Send it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.post(`/entry/${this.esid}/tib/close`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('TIB Closure has been Sent.')
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Cannot send TIB Closure.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
  },
}

</script>
