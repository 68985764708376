<template>
  <div>
    <b-row>
      <b-col md="12">
        <validation-observer
          ref="feeRules"
          tag="form"
        >
          <b-button-toolbar justify>
            <div>
              <b-button
                v-b-tooltip.hover="'Refresh notification'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                @click="CalculateFees()"
              >
                <font-awesome-icon
                  :icon="['fas', 'calculator']"
                  size="lg"
                />
              </b-button>
            </div>
            <div>
              <b-button
                v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                @click="UpdateFees()"
              >
                <font-awesome-icon
                  :icon="['far', 'save']"
                  size="lg"
                />
              </b-button>
            </div>
          </b-button-toolbar>
          <div class="divider my-2">
            <div class="divider-text">
              Header Fees
            </div>
          </div>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Informal MPF"
                  rules="required|min_value:0|max_value:2.18|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Informal MPF:</b></i></label>
                    <b-form-input
                      id="Informal"
                      v-model="fees.Informal"
                      type="number"
                      min="0"
                      max="2.18"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Manual Surcharge"
                  rules="required|min_value:0|max_value:3.27|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Manual Surcharge:</b></i></label>
                    <b-form-input
                      id="ManualSurcharge"
                      v-model="fees.ManualSurcharge"
                      type="number"
                      min="0"
                      max="3.27"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Dutiable Mail"
                  rules="required|min_value:0|max_value:5.99|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Dutiable Mail:</b></i></label>
                    <b-form-input
                      id="Mail"
                      v-model="fees.Mail"
                      type="number"
                      min="0"
                      max="5.99"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              Formal Fees
            </div>
          </div>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Duty"
                  rules="required|min_value:0|max_value:9999999.99|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Duty:</b></i></label>
                    <b-form-input
                      id="Duty"
                      v-model="fees.Duty"
                      type="number"
                      min="0"
                      max="99999999.99"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="MPF"
                  rules="required|min_value:0|max_value:528.33"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>MPF:</b></i></label>
                    <b-form-input
                      id="MPF"
                      v-model="fees.MPF"
                      type="number"
                      min="0"
                      max="528.33"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="HMF"
                  rules="required|min_value:0|max_value:9999999.99|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>HMF:</b></i></label>
                    <b-form-input
                      id="HMF"
                      v-model="fees.HMF"
                      type="number"
                      min="0"
                      max="99999999.99"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Total Entered Value"
                  rules="required|min_value:0|max_value:9999999.99|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>TEV:</b></i></label>
                    <b-form-input
                      id="TotalEnteredValue"
                      v-model="fees.TotalEnteredValue"
                      type="number"
                      min="0"
                      max="99999999.99"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Fees"
                  rules="required|min_value:0|max_value:9999999.99|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Fees:</b></i></label>
                    <b-form-input
                      id="Fees"
                      v-model="fees.Fees"
                      type="number"
                      min="0"
                      max="99999999.99"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Tax"
                  rules="required|min_value:0|max_value:9999999.99|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Tax:</b></i></label>
                    <b-form-input
                      id="Tax"
                      v-model="fees.Tax"
                      type="number"
                      min="0"
                      max="99999999.99"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              AD/CVD
            </div>
          </div>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Antidumping"
                  rules="required|min_value:0|max_value:9999999.99|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Antidumping Cash:</b></i></label>
                    <b-form-input
                      id="Antidumping"
                      v-model="fees.Antidumping"
                      type="number"
                      min="0"
                      max="99999999.99"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Antidumping Bond"
                  rules="required|min_value:0|max_value:9999999.99|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Antidumping Bonded:</b></i></label>
                    <b-form-input
                      id="AntidumpingBonded"
                      v-model="fees.AntidumpingBonded"
                      type="number"
                      min="0"
                      max="99999999.99"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Countervailing"
                  rules="required|min_value:0|max_value:9999999.99|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Countervailing Cash:</b></i></label>
                    <b-form-input
                      id="Countervailing"
                      v-model="fees.Countervailing"
                      type="number"
                      min="0"
                      max="99999999.99"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="ountervailing Bond"
                  rules="required|min_value:0|max_value:9999999.99|positive"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Countervailing Bonded:</b></i></label>
                    <b-form-input
                      id="CountervailingBonded"
                      v-model="fees.CountervailingBonded"
                      type="number"
                      min="0"
                      max="99999999.99"
                      step="0.01"
                      :state="errors.length > 0 ? false:null"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@axios'
import {
  required, maxvalue, minvalue, positive,
} from '@validations'

export default {
  name: 'EsFees',
  components: {
  },
  props: {
    esid: { type: String, default: '' },
    inace: { type: String, default: '' },
  },
  data() {
    return {
      required,
      maxvalue,
      minvalue,
      positive,
      fees: {
        Id: '',
        Duty: 0.00,
        Antidumping: 0.00,
        Countervailing: 0.00,
        AntidumpingBonded: 0.00,
        CountervailingBonded: 0.00,
        Fees: 0.00,
        Tax: 0.00,
        HMF: 0.00,
        MPF: 0.00,
        Informal: 0.00,
        ManualSurcharge: 0.00,
        TotalEnteredValue: 0.00,
        Mail: 0.00,
      },
    }
  },
  beforeMount() {
    // Load Data on select
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadFees()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadFees() {
      axios.get(`/entry/${this.esid}/summary/fees`).then(resp => {
        const response = resp.data
        const respdata = response[0]
        this.fees.Id = respdata.id
        this.fees.Duty = respdata.duty
        this.fees.Antidumping = respdata.antidumping
        this.fees.Countervailing = respdata.countervailing
        this.fees.AntidumpingBonded = respdata.antidumpingBonded
        this.fees.CountervailingBonded = respdata.countervailingBonded
        this.fees.Fees = respdata.fees
        this.fees.Tax = respdata.tax
        this.fees.HMF = respdata.hmf
        this.fees.MPF = respdata.mpf
        this.fees.Informal = respdata.informal
        this.fees.Mail = respdata.mail
        this.fees.ManualSurcharge = respdata.manualSurcharge
        this.fees.TotalEnteredValue = respdata.totalEnteredValue
      })
    },
    UpdateFees() {
      this.$refs.feeRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ESFeesModel = {
            Id: this.fees.Id,
            ESId: this.esid,
            Duty: this.fees.Duty,
            Antidumping: this.fees.Antidumping,
            Countervailing: this.fees.Countervailing,
            AntidumpingBonded: this.fees.AntidumpingBonded,
            CountervailingBonded: this.fees.CountervailingBonded,
            Fees: this.fees.Fees,
            Tax: this.fees.Tax,
            HMF: this.fees.HMF,
            MPF: this.fees.MPF,
            Informal: this.fees.Informal,
            Mail: this.fees.Mail,
            ManualSurcharge: this.fees.ManualSurcharge,
            TotalEnteredValue: this.fees.TotalEnteredValue,
          }
          // Post
          axios.put(`/entry/${this.esid}/summary/fees`, ESFeesModel)
            .then(() => {
              this.LoadingEnd()
              this.DisplaySuccess('Summary Fees have been saved.')
            })
            .catch(e => {
              this.LoadingEnd()
              this.DisplayError('Summary Fees cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Summary Fees cannot be saved.', 'Please check data requirements.')
        }
      })
    },
  },
}

</script>
