<template>
  <div>
    <b-row>
      <b-col md="12">
        <validation-observer
          ref="dateRules"
          tag="form"
        >
          <b-button-toolbar justify>
            <div />
            <div>
              <b-button
                v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                @click="UpdateDates()"
              >
                <font-awesome-icon
                  :icon="['far', 'save']"
                  size="lg"
                />
              </b-button>
            </div>
          </b-button-toolbar>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Export Date"
                  vid="ExportDate"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Export Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.Export"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :reset-button="true"
                      @input="changeExport"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry Date"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Entry Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.Entry"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :min.sync="minExportDate"
                      :reset-button="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Import Date"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Import Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.Import"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :reset-button="true"
                      :min.sync="minExportDate"
                      @input="changeImport"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Tariff Date"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Tariff Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.Tariff"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :reset-button="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="IT Date"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>IT Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.IT"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :reset-button="true"
                      :min.sync="minImportDate"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Arrival Date"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Arrival Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.Arrival"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :min.sync="minImportDate"
                      :reset-button="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Presentation Date"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Presentation Date / Preliminary Statement Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.Presentation"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :reset-button="true"
                      :min.sync="minExportDate"
                      @input="changePresent"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Summary Date"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Summary Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.Summary"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :min.sync="minPresentDate"
                      :reset-button="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Release Date"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Release Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.Release"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :reset-button="true"
                      readonly
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Liquidation Date"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Liquidation Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.Liquidation"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :reset-button="true"
                      readonly
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Due Date"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Due Date:</b></i></label>
                    <b-form-datepicker
                      v-model="esdates.Due"
                      placeholder="MM/DD/YYYY"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                      locale="en"
                      :reset-button="true"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Election"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Election:</b></i></label>
                    <v-select
                      id="Election"
                      v-model="esdates.Election"
                      :options="electionoptions"
                      label="text"
                      placeholder="Select the election"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'

export default {
  name: 'EsDates',
  props: {
    esid: { type: String, default: '' },
    inace: { type: String, default: '' },
    et: { type: String, default: '' },
    mot: { type: String, default: '' },
  },
  data() {
    return {
      minExportDate: '',
      minImportDate: '',
      minPresentDate: '',
      required,
      electionoptions: [
        { text: 'Arrival', value: 'A' },
        { text: 'Presentation', value: 'P' },
        { text: 'Estimate', value: 'E' },
      ],
      esdates: {
        Id: '',
        Export: '',
        Import: '',
        IT: '',
        Arrival: '',
        Tariff: '',
        Election: null,
        Entry: '',
        Summary: '',
        Release: '',
        Presentation: '',
        Due: '',
        Liquidation: '',
      },
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    // Load Data on select
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadDates()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadDates() {
      axios.get(`/entry/${this.esid}/summary/dates`).then(resp => {
        const response = resp.data
        const respdata = response[0]
        this.esdates.Id = respdata.id
        this.esdates.Export = new Date(respdata.exportDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.exportDate) : null
        this.minExportDate = this.esdates.Export
        this.esdates.Import = new Date(respdata.importDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.importDate) : null
        this.minImportDate = this.esdates.Import
        this.esdates.IT = new Date(respdata.itDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.itDate) : null
        this.esdates.Arrival = new Date(respdata.arrivalDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.arrivalDate) : null
        this.esdates.Tariff = new Date(respdata.tariffDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.tariffDate) : null
        this.esdates.Election = this.electionoptions.find(option => option.value === respdata.election)
        this.esdates.Entry = new Date(respdata.entryDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.entryDate) : null
        this.esdates.Summary = new Date(respdata.summaryDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.summaryDate) : null
        this.esdates.Release = new Date(respdata.releaseDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.releaseDate) : null
        this.esdates.Presentation = new Date(respdata.presentationDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.presentationDate) : null
        this.minPresentDate = this.esdates.Presentation
        this.esdates.Due = new Date(respdata.dueDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.dueDate) : null
        this.esdates.Liquidation = new Date(respdata.liquidationDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.liquidationDate) : null
      })
    },
    UpdateDates() {
      this.$refs.dateRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ESDatesModel = {
            Id: this.esdates.Id,
            ESId: this.esid,
            PresentationDate: this.esdates.Presentation, // new Date(this.esdates.Presentation).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.Presentation).toJSON().split('T')[0] : '',
            ExportDate: this.esdates.Export, // new Date(this.esdates.Export).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.Export).toJSON().split('T')[0] : '',
            ImportDate: this.esdates.Import, // new Date(this.esdates.Import).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.Import).toJSON().split('T')[0] : '',
            ITDate: this.esdates.IT, // new Date(this.esdates.IT).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.IT).toJSON().split('T')[0] : '',
            ArrivalDate: this.esdates.Arrival, // new Date(this.esdates.Arrival).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.Arrival).toJSON().split('T')[0] : '',
            TariffDate: this.esdates.Tariff, // new Date(this.esdates.Tariff).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.Tariff).toJSON().split('T')[0] : '',
            ReleaseDate: this.esdates.Release, // new Date(this.esdates.Release).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.Release).toJSON().split('T')[0] : '',
            Election: this.esdates.Election.value,
            EntryDate: this.esdates.Entry, // new Date(this.esdates.Entry).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.Entry).toJSON().split('T')[0] : '',
            SummaryDate: this.esdates.Summary, // new Date(this.esdates.Summary).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.Summary).toJSON().split('T')[0] : '',
            DueDate: this.esdates.Due, // new Date(this.esdates.Due).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.Due).toJSON().split('T')[0] : '',
            LiquidationDate: this.esdates.Liquidation, // new Date(this.esdates.Liquidation).toJSON().split('T')[0] !== '1970-01-01' ? new Date(this.esdates.Liquidation).toJSON().split('T')[0] : '',

          }
          // Post
          axios.put(`/entry/${this.esid}/summary/dates`, ESDatesModel)
            .then(() => {
              this.LoadingEnd()
              this.DisplaySuccess('Dates have been saved.')
            })
            .catch(e => {
              this.LoadingEnd()
              this.DisplayError('Dates cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Dates cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    changeExport() {
      const x = new Date(this.esdates.Export)
      x.setDate(x.getDate() + 1)
      this.minExportDate = x.toJSON().split('T')[0] !== '1970-01-01' ? x : null
    },
    changeImport() {
      const x = new Date(this.esdates.Import)
      x.setDate(x.getDate() + 1)
      this.minExportDate = x.toJSON().split('T')[0] !== '1970-01-01' ? x : null
    },
    changePresent() {
      const x = new Date(this.esdates.Presentation)
      x.setDate(x.getDate() + 1)
      this.minPresentDate = x.toJSON().split('T')[0] !== '1970-01-01' ? x : null
    },
  },
}

</script>
