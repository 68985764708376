<template>
  <div>
    <b-row>
      <b-col
        md="4"
        offset-md="8"
      >
        <b-button-toolbar justify>
          <div />
          <div>
            <b-button
              v-b-tooltip.hover="'Refresh notification'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mb-1"
              @click="LoadResponse()"
            >
              <font-awesome-icon
                :icon="['fas', 'sync']"
                size="lg"
              />
            </b-button>
          </div>
        </b-button-toolbar>

      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <label :style="{color:color}">{{ FinalDispositionCode + ' / ' + FinalDispositionDescription }}</label>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-table
          v-if="ResponseTable"
          :fields="fields"
          :items="ResponseTable"
          responsive=""
          hover
        >
          <template #cell(code)="data">
            {{ data.item.conditionReason + ' (' + data.item.conditionCode + ')' }}
          </template>
          <template #cell(ReferenceData)="data">
            <ul>
              <li
                v-for="(ReferenceData, x) in data.item.references"
                :key="x"
              >
                {{ ReferenceData.data + ': ' + ReferenceData.value }}
              </li>
            </ul>
          </template>
          <template #cell(Dispositions)="data">
            <ul>
              <li
                v-for="(Dispositions, y) in data.item.dispositions"
                :key="y"
              >
                {{ Dispositions.description + ' (' + Dispositions.code + ')' }}
              </li>
            </ul>
          </template>
          <template #cell(ResponseDate)>
            {{ AddedDate }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  name: 'EsResponse',
  props: {
    esid: { type: String, default: '' },
  },
  data() {
    return {
      fields: [
        { key: 'code', lable: 'Code' },
        { key: 'ReferenceData', lable: 'Reference Data' },
        { key: 'Dispositions', lable: 'Dispositions' },
        { key: 'ResponseDate', lable: 'Response Date' },
      ],
      ResponseTable: [],
      FinalDispositionCode: '',
      FinalDispositionDescription: '',
      AddedDate: '',
      color: '',

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      return Promise.all([this.LoadingStart(), this.LoadResponse()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadResponse() {
      return axios.get(`/entry/${this.esid}/summary/response`)
        .then(resp => {
          if (resp.data[0] != null && resp.data[0]) {
            this.ResponseTable = resp.data[0].conditions
            this.FinalDispositionCode = resp.data[0].dispositionCode
            this.FinalDispositionDescription = resp.data[0].dispositionDescription
            this.AddedDate = resp.data[0].addedDate
            // eslint-disable-next-line no-nested-ternary
            this.color = resp.data[0].dispositionCode === 'RF' ? 'red' : resp.data[0].dispositionCode === 'A' ? 'green' : 'darkorange'
          } else {
            this.FinalDispositionCode = 'Pending'
            this.FinalDispositionDescription = 'No Response'
          }
        })
    },
  },
}
</script>
