var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"feeRules",attrs:{"tag":"form"}},[_c('b-button-toolbar',{attrs:{"justify":""}},[_c('div',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Refresh notification'),expression:"'Refresh notification'",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.CalculateFees()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'calculator'],"size":"lg"}})],1)],1),_c('div',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Update in Database (Does not update in CBP system)'),expression:"'Update in Database (Does not update in CBP system)'",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.UpdateFees()}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'save'],"size":"lg"}})],1)],1)]),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" Header Fees ")])]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Informal MPF","rules":"required|min_value:0|max_value:2.18|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Informal MPF:")])])]),_c('b-form-input',{attrs:{"id":"Informal","type":"number","min":"0","max":"2.18","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.Informal),callback:function ($$v) {_vm.$set(_vm.fees, "Informal", $$v)},expression:"fees.Informal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Manual Surcharge","rules":"required|min_value:0|max_value:3.27|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Manual Surcharge:")])])]),_c('b-form-input',{attrs:{"id":"ManualSurcharge","type":"number","min":"0","max":"3.27","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.ManualSurcharge),callback:function ($$v) {_vm.$set(_vm.fees, "ManualSurcharge", $$v)},expression:"fees.ManualSurcharge"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Dutiable Mail","rules":"required|min_value:0|max_value:5.99|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Dutiable Mail:")])])]),_c('b-form-input',{attrs:{"id":"Mail","type":"number","min":"0","max":"5.99","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.Mail),callback:function ($$v) {_vm.$set(_vm.fees, "Mail", $$v)},expression:"fees.Mail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" Formal Fees ")])]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Duty","rules":"required|min_value:0|max_value:9999999.99|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Duty:")])])]),_c('b-form-input',{attrs:{"id":"Duty","type":"number","min":"0","max":"99999999.99","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.Duty),callback:function ($$v) {_vm.$set(_vm.fees, "Duty", $$v)},expression:"fees.Duty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"MPF","rules":"required|min_value:0|max_value:528.33"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("MPF:")])])]),_c('b-form-input',{attrs:{"id":"MPF","type":"number","min":"0","max":"528.33","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.MPF),callback:function ($$v) {_vm.$set(_vm.fees, "MPF", $$v)},expression:"fees.MPF"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"HMF","rules":"required|min_value:0|max_value:9999999.99|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("HMF:")])])]),_c('b-form-input',{attrs:{"id":"HMF","type":"number","min":"0","max":"99999999.99","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.HMF),callback:function ($$v) {_vm.$set(_vm.fees, "HMF", $$v)},expression:"fees.HMF"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Total Entered Value","rules":"required|min_value:0|max_value:9999999.99|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("TEV:")])])]),_c('b-form-input',{attrs:{"id":"TotalEnteredValue","type":"number","min":"0","max":"99999999.99","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.TotalEnteredValue),callback:function ($$v) {_vm.$set(_vm.fees, "TotalEnteredValue", $$v)},expression:"fees.TotalEnteredValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Fees","rules":"required|min_value:0|max_value:9999999.99|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Fees:")])])]),_c('b-form-input',{attrs:{"id":"Fees","type":"number","min":"0","max":"99999999.99","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.Fees),callback:function ($$v) {_vm.$set(_vm.fees, "Fees", $$v)},expression:"fees.Fees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Tax","rules":"required|min_value:0|max_value:9999999.99|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Tax:")])])]),_c('b-form-input',{attrs:{"id":"Tax","type":"number","min":"0","max":"99999999.99","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.Tax),callback:function ($$v) {_vm.$set(_vm.fees, "Tax", $$v)},expression:"fees.Tax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" AD/CVD ")])]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Antidumping","rules":"required|min_value:0|max_value:9999999.99|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Antidumping Cash:")])])]),_c('b-form-input',{attrs:{"id":"Antidumping","type":"number","min":"0","max":"99999999.99","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.Antidumping),callback:function ($$v) {_vm.$set(_vm.fees, "Antidumping", $$v)},expression:"fees.Antidumping"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Antidumping Bond","rules":"required|min_value:0|max_value:9999999.99|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Antidumping Bonded:")])])]),_c('b-form-input',{attrs:{"id":"AntidumpingBonded","type":"number","min":"0","max":"99999999.99","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.AntidumpingBonded),callback:function ($$v) {_vm.$set(_vm.fees, "AntidumpingBonded", $$v)},expression:"fees.AntidumpingBonded"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Countervailing","rules":"required|min_value:0|max_value:9999999.99|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Countervailing Cash:")])])]),_c('b-form-input',{attrs:{"id":"Countervailing","type":"number","min":"0","max":"99999999.99","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.Countervailing),callback:function ($$v) {_vm.$set(_vm.fees, "Countervailing", $$v)},expression:"fees.Countervailing"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"ountervailing Bond","rules":"required|min_value:0|max_value:9999999.99|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Countervailing Bonded:")])])]),_c('b-form-input',{attrs:{"id":"CountervailingBonded","type":"number","min":"0","max":"99999999.99","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.fees.CountervailingBonded),callback:function ($$v) {_vm.$set(_vm.fees, "CountervailingBonded", $$v)},expression:"fees.CountervailingBonded"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }