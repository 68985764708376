var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('AgGrid',{ref:"ESDetailLineGrid",attrs:{"grid":_vm.aggrid},on:{"LoadAll":_vm.LoadAll},scopedSlots:_vm._u([{key:"lftbtn",fn:function(){return [(_vm.inace !== 'Pending' )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Add a new line'),expression:"'Add a new line'",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.CreateNewLine()}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'plus-square'],"size":"lg"}})],1):_vm._e()]},proxy:true}])})],1),_c('b-modal',{ref:"CreateLine",attrs:{"id":"CreateLineModal","title":"New Line","size":"lg","centered":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.SaveNewLine()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Save")])])],1)]},proxy:true}])},[_c('validation-observer',{ref:"headerRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Invoice #","rules":"required|max:17"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Invoice #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"NewInvoiceNumber","state":errors.length > 0 ? false:null,"placeholder":"Invoice Number"},model:{value:(_vm.line.InvoiceNumber),callback:function ($$v) {_vm.$set(_vm.line, "InvoiceNumber", $$v)},expression:"line.InvoiceNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Invoice Range Begin","rules":"max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Invoice Range Begin:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"NewInvRangeB","state":errors.length > 0 ? false:null,"placeholder":"Begin"},model:{value:(_vm.line.InvRangeB),callback:function ($$v) {_vm.$set(_vm.line, "InvRangeB", $$v)},expression:"line.InvRangeB"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Invoice Range End","rules":"max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Invoice Range End:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"NewInvRangeE","state":errors.length > 0 ? false:null,"placeholder":"End"},model:{value:(_vm.line.InvRangeE),callback:function ($$v) {_vm.$set(_vm.line, "InvRangeE", $$v)},expression:"line.InvRangeE"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Part Number","rules":"max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('i',[_c('b',[_vm._v("Part Number:")])])]),_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"NewPartNumber","state":errors.length > 0 ? false:null,"placeholder":"Part Number"},model:{value:(_vm.line.PartNumber),callback:function ($$v) {_vm.$set(_vm.line, "PartNumber", $$v)},expression:"line.PartNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Article Set","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Article Set:")])])]),_c('v-select',{attrs:{"id":"NewArticleSet","options":_vm.ArticleSetOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.line.ArticleSet),callback:function ($$v) {_vm.$set(_vm.line, "ArticleSet", $$v)},expression:"line.ArticleSet"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Goods Description","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Goods Description:")])])]),_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"NewDescription","state":errors.length > 0 ? false:null,"placeholder":"Goods Description"},model:{value:(_vm.line.Description),callback:function ($$v) {_vm.$set(_vm.line, "Description", $$v)},expression:"line.Description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Country of Origin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Country of Origin:")])])]),_c('v-select',{attrs:{"id":"NewCOO","options":_vm.CountryOptions,"label":"country","placeholder":"Select the country"},model:{value:(_vm.line.COO),callback:function ($$v) {_vm.$set(_vm.line, "COO", $$v)},expression:"line.COO"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Export Country:","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Export Country:")])])]),_c('v-select',{attrs:{"id":"NewCOE","options":_vm.CountryOptions,"label":"country","placeholder":"Select the country"},model:{value:(_vm.line.COE),callback:function ($$v) {_vm.$set(_vm.line, "COE", $$v)},expression:"line.COE"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Port of Loading","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{},[_c('i',[_c('b',[_vm._v("Port of Loading (Foreign):")])])]),_c('v-select',{attrs:{"id":"NewPOL","options":_vm.ForeignPortsOptions,"label":"foreignPOLName","placeholder":"Select a port"},model:{value:(_vm.line.POL),callback:function ($$v) {_vm.$set(_vm.line, "POL", $$v)},expression:"line.POL"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"MID","rules":"required|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("MID #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"NewMID","state":errors.length > 0 ? false:null,"placeholder":"MID"},model:{value:(_vm.line.MID),callback:function ($$v) {_vm.$set(_vm.line, "MID", $$v)},expression:"line.MID"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Related Manufacturer:")])])]),_c('b-form-checkbox',{model:{value:(_vm.line.ManufactureRelated),callback:function ($$v) {_vm.$set(_vm.line, "ManufactureRelated", $$v)},expression:"line.ManufactureRelated"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Ruling Type","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{},[_c('i',[_c('b',[_vm._v("Ruling Type:")])])]),_c('v-select',{attrs:{"id":"NewRulingType","options":_vm.RulingOptions,"label":"text","placeholder":"Select a type"},model:{value:(_vm.line.RulingType),callback:function ($$v) {_vm.$set(_vm.line, "RulingType", $$v)},expression:"line.RulingType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Ruling Number","rules":("" + (_vm.line.RulingType && (_vm.line.RulingType.value !== '') ? 'required|max:6' : 'max:6'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{},[_c('i',[_c('b',[_vm._v("Ruling #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"NewRulingNumber","state":errors.length > 0 ? false:null,"placeholder":"Ruling Number"},model:{value:(_vm.line.RulingNumber),callback:function ($$v) {_vm.$set(_vm.line, "RulingNumber", $$v)},expression:"line.RulingNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }