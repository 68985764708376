<template>
  <div>
    <b-row>
      <b-col md="12">
        <validation-observer
          ref="addlpartyRules"
          tag="form"
        >
          <b-button-toolbar justify>
            <div />
            <div>
              <b-button
                v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                @click="UpdateAddParty()"
              >
                <font-awesome-icon
                  :icon="['far', 'save']"
                  size="lg"
                />
              </b-button>
            </div>
          </b-button-toolbar>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Manufacturer/Supplier"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Manufacturer/Supplier:</b></i></label> &nbsp;<small @click="LoadAddress('manufacturer')"><u>View Address</u></small>
                    <v-select
                      id="ManufacturerId"
                      v-model="party.ManufacturerId"
                      :options="ManufacturerOptions"
                      label="name"
                      placeholder="Select the party"
                      @input="onChangeManufacturer"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label><i><b>EIN/MID/CBP Assigned:</b></i></label>
                <b-form-input
                  id="ManufacturerIdentifier"
                  v-model="party.ManufacturerIdentifier"
                  v-uppercase
                  readonly
                  placeholder="EIN/MID/CBP Assigned"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Seller"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Seller:</b></i></label> &nbsp;<small @click="LoadAddress('seller')"><u>View Address</u></small>
                    <v-select
                      id="SellerId"
                      v-model="party.SellerId"
                      :options="SellerOptions"
                      label="name"
                      placeholder="Select the party"
                      @input="onChangeSeller"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label><i><b>EIN/MID/CBP Assigned:</b></i></label>
                <b-form-input
                  id="SellerIdentifier"
                  v-model="party.SellerIdentifier"
                  v-uppercase
                  readonly
                  placeholder="EIN/MID/CBP Assigned"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Buyer"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Buyer:</b></i></label> &nbsp;<small @click="LoadAddress('buyer')"><u>View Address</u></small>
                    <v-select
                      id="BuyerId"
                      v-model="party.BuyerId"
                      :options="BuyerOptions"
                      label="name"
                      placeholder="Select the party"
                      @input="onChangeBuyer"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label><i><b>EIN/MID/CBP Assigned:</b></i></label>
                <b-form-input
                  id="BuyerIdentifier"
                  v-model="party.BuyerIdentifier"
                  v-uppercase
                  readonly
                  placeholder="EIN/MID/CBP Assigned"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Consolidator"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Consolidator:</b></i></label> &nbsp;<small @click="LoadAddress('consolidator')"><u>View Address</u></small>
                    <v-select
                      id="ConsolidatorId"
                      v-model="party.ConsolidatorId"
                      :options="ConsolidatorOptions"
                      label="name"
                      placeholder="Select the party"
                      @input="onChangeConsolidator"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label><i><b>EIN/MID/CBP Assigned:</b></i></label>
                <b-form-input
                  id="ConsolidatorIdentifier"
                  v-model="party.ConsolidatorIdentifier"
                  v-uppercase
                  readonly
                  placeholder="EIN/MID/CBP Assigned"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Container Stuffing Location"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Container Stuffing Location:</b></i></label> &nbsp;<small @click="LoadAddress('containerstuffing')"><u>View Address</u></small>
                    <v-select
                      id="ContainerStuffingId"
                      v-model="party.ContainerStuffingId"
                      :options="ContainerStuffingOptions"
                      label="name"
                      placeholder="Select the party"
                      @input="onChangeContainerStuffing"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label><i><b>EIN/MID/CBP Assigned:</b></i></label>
                <b-form-input
                  id="ContainerStuffingIdentifier"
                  v-model="party.ContainerStuffingIdentifier"
                  v-uppercase
                  readonly
                  placeholder="EIN/MID/CBP Assigned"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Booking Party"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Booking Party:</b></i></label> &nbsp;<small @click="LoadAddress('bookingparty')"><u>View Address</u></small>
                    <v-select
                      id="BookingPartyId"
                      v-model="party.BookingPartyId"
                      :options="BookingPartyOptions"
                      label="name"
                      placeholder="Select the party"
                      @input="onChangeBookingParty"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label><i><b>EIN/MID/CBP Assigned:</b></i></label>
                <b-form-input
                  id="BookingPartyIdentifier"
                  v-model="party.BookingPartyIdentifier"
                  v-uppercase
                  readonly
                  placeholder="EIN/MID/CBP Assigned"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Notify Party"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Notify Party:</b></i></label> &nbsp;<small @click="LoadAddress('notifyparty')"><u>View Address</u></small>
                    <v-select
                      id="NotifyPartyId"
                      v-model="party.NotifyPartyId"
                      :options="NotifyPartyOptions"
                      label="name"
                      placeholder="Select the party"
                      @input="onChangeNotifyParty"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label><i><b>EIN/MID/CBP Assigned:</b></i></label>
                <b-form-input
                  id="NotifyPartyIdentifier"
                  v-model="party.NotifyPartyIdentifier"
                  v-uppercase
                  readonly
                  placeholder="EIN/MID/CBP Assigned"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  name: 'EsAddlparty',
  props: {
    esid: { type: String, default: '' },
    inace: { type: String, default: '' },
    customerid: { type: Number, default: 0 },
    et: { type: String, default: '' },
    mot: { type: String, default: '' },
  },
  data() {
    return {
      ManufacturerOptions: [],
      SellerOptions: [],
      BuyerOptions: [],
      ConsolidatorOptions: [],
      ContainerStuffingOptions: [],
      BookingPartyOptions: [],
      NotifyPartyOptions: [],
      party: {
        MAddress: '',
        PAddress: '',
        ManufacturerId: '',
        ManufacturerIdentifier: '',
        SellerId: '',
        SellerIdentifier: '',
        BuyerId: '',
        BuyerIdentifier: '',
        ConsolidatorId: '',
        ConsolidatorIdentifier: '',
        ContainerStuffingId: '',
        ContainerStuffingIdentifier: '',
        BookingPartyId: '',
        BookingPartyIdentifier: '',
        NotifyPartyId: '',
        NotifyPartyIdentifier: '',
      },
      temp: {
        ManufacturerId: '',
        SellerId: '',
        BuyerId: '',
        ConsolidatorId: '',
        ContainerStuffingId: '',
        BookingPartyId: '',
        NotifyPartyId: '',
      },
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      return Promise.all(
        [this.LoadingStart(), this.LoadParties(), this.LoadManufacturerList(), this.LoadSellerList(),
          this.LoadBuyerList(), this.LoadConsolidatorList(), this.LoadContainerStuffingList(),
          this.LoadBookingPartyList(), this.LoadNotifyPartyList(),
        ],
      ).then(() => {
        this.party.ManufacturerId = this.ManufacturerOptions.find(option => option.partyId === this.temp.ManufacturerId)
        // eslint-disable-next-line no-nested-ternary
        this.party.ManufacturerIdentifier = this.CheckNull(this.party.ManufacturerId) ? this.party.ManufacturerId.ein === '' ? this.party.ManufacturerId.cbpAssigned : this.party.ManufacturerId.ein : ''

        this.party.SellerId = this.SellerOptions.find(option => option.partyId === this.temp.SellerId)
        // eslint-disable-next-line no-nested-ternary
        this.party.SellerIdentifier = this.CheckNull(this.party.SellerId) ? this.party.SellerId.ein === '' ? this.party.SellerId.cbpAssigned : this.party.SellerId.ein : ''

        this.party.BuyerId = this.BuyerOptions.find(option => option.partyId === this.temp.BuyerId)
        // eslint-disable-next-line no-nested-ternary
        this.party.BuyerIdentifier = this.CheckNull(this.party.BuyerId) ? (this.party.BuyerId.ein === '' ? this.party.BuyerId.cbpAssigned : this.party.BuyerId.ein) : ''

        this.party.ConsolidatorId = this.ConsolidatorOptions.find(option => option.partyId === this.temp.ConsolidatorId)
        // eslint-disable-next-line no-nested-ternary
        this.party.ConsolidatorIdentifier = this.CheckNull(this.party.ConsolidatorId) ? (this.party.ConsolidatorId.ein === '' ? this.party.ConsolidatorId.cbpAssigned : this.party.ConsolidatorId.ein) : ''

        this.party.ContainerStuffingId = this.ContainerStuffingOptions.find(option => option.partyId === this.temp.ContainerStuffingId)
        // eslint-disable-next-line no-nested-ternary
        this.party.ContainerStuffingIdentifier = this.CheckNull(this.party.ContainerStuffingId) ? (this.party.ContainerStuffingId.ein === '' ? this.party.ContainerStuffingId.cbpAssigned : this.party.ContainerStuffingId.ein) : ''

        this.party.BookingPartyId = this.BookingPartyOptions.find(option => option.partyId === this.temp.BookingPartyId)
        // eslint-disable-next-line no-nested-ternary
        this.party.BookingPartyIdentifier = this.CheckNull(this.party.BookingPartyId) ? (this.party.BookingPartyId.ein === '' ? this.party.BookingPartyId.cbpAssigned : this.party.BookingPartyId.ein) : ''

        this.party.NotifyPartyId = this.NotifyPartyOptions.find(option => option.partyId === this.temp.NotifyPartyId)
        // eslint-disable-next-line no-nested-ternary
        this.party.NotifyPartyIdentifier = this.CheckNull(this.party.NotifyPartyId) ? (this.party.NotifyPartyId.ein === '' ? this.party.NotifyPartyId.cbpAssigned : this.party.NotifyPartyId.ein) : ''

        this.LoadingEnd()
      })
    },
    LoadParties() {
      //
      return axios.get(`/entry/${this.esid}/summary/party`)
        .then(resp => {
          const response = resp.data
          const respdata = response[0]
          this.temp.ManufacturerId = respdata.manufacturer
          this.temp.SellerId = respdata.seller
          this.temp.BuyerId = respdata.buyer
          this.temp.ConsolidatorId = respdata.consolidator
          this.temp.ContainerStuffingId = respdata.containerStuffing
          this.temp.BookingPartyId = respdata.bookingParty
          this.temp.NotifyPartyId = respdata.notifyParty
        })
    },
    LoadManufacturerList() {
      return axios.get(`/abi/party/${this.customerid}/manufacturer`)
        .then(rowData => {
          this.ManufacturerOptions = rowData.data
        })
    },
    LoadSellerList() {
      return axios.get(`/abi/party/${this.customerid}/seller`)
        .then(rowData => {
          this.SellerOptions = rowData.data
        })
    },
    LoadBuyerList() {
      return axios.get(`/abi/party/${this.customerid}/buyer`)
        .then(rowData => {
          this.BuyerOptions = rowData.data
        })
    },
    LoadConsolidatorList() {
      return axios.get(`/abi/party/${this.customerid}/consolidator`)
        .then(rowData => {
          this.ConsolidatorOptions = rowData.data
        })
    },
    LoadContainerStuffingList() {
      return axios.get(`/abi/party/${this.customerid}/containerstuffing`)
        .then(rowData => {
          this.ContainerStuffingOptions = rowData.data
        })
    },
    LoadBookingPartyList() {
      return axios.get(`/abi/party/${this.customerid}/bookingparty`)
        .then(rowData => {
          this.BookingPartyOptions = rowData.data
        })
    },
    LoadNotifyPartyList() {
      return axios.get(`/abi/party/${this.customerid}/notifyparty`)
        .then(rowData => {
          this.NotifyPartyOptions = rowData.data
        })
    },
    LoadAddress(type) {
      let mailing
      let physical
      // eslint-disable-next-line default-case
      switch (type) {
        case 'manufacturer':
          this.party.MAddress = this.CheckNull(this.party.ManufacturerId) ? this.party.ManufacturerId.mailingAddress : ''
          this.party.PAddress = this.CheckNull(this.party.ManufacturerId) ? this.party.ManufacturerId.physicalAddress : ''
          mailing = this.party.MAddress.split('`')
          physical = this.party.PAddress.split('`')
          break
        case 'seller':
          this.party.MAddress = this.CheckNull(this.party.SellerId) ? this.party.SellerId.mailingAddress : ''
          this.party.PAddress = this.CheckNull(this.party.SellerId) ? this.party.SellerId.physicalAddress : ''
          mailing = this.party.MAddress.split('`')
          physical = this.party.PAddress.split('`')
          break
        case 'buyer':
          this.party.MAddress = this.CheckNull(this.party.BuyerId) ? this.party.BuyerId.mailingAddress : ''
          this.party.PAddress = this.CheckNull(this.party.BuyerId) ? this.party.BuyerId.physicalAddress : ''
          mailing = this.party.MAddress.split('`')
          physical = this.party.PAddress.split('`')
          break
        case 'consolidator':
          this.party.MAddress = this.CheckNull(this.party.ConsolidatorId) ? this.party.ConsolidatorId.mailingAddress : ''
          this.party.PAddress = this.CheckNull(this.party.ConsolidatorId) ? this.party.ConsolidatorId.physicalAddress : ''
          mailing = this.party.MAddress.split('`')
          physical = this.party.PAddress.split('`')
          break
        case 'containerstuffing':
          this.party.MAddress = this.CheckNull(this.party.ContainerStuffingId) ? this.party.ContainerStuffingId.mailingAddress : ''
          this.party.PAddress = this.CheckNull(this.party.ContainerStuffingId) ? this.party.ContainerStuffingId.physicalAddress : ''
          mailing = this.party.MAddress.split('`')
          physical = this.party.PAddress.split('`')
          break
        case 'bookingparty':
          this.party.MAddress = this.CheckNull(this.party.BookingPartyId) ? this.party.BookingPartyId.mailingAddress : ''
          this.party.PAddress = this.CheckNull(this.party.BookingPartyId) ? this.party.BookingPartyId.physicalAddress : ''

          mailing = this.party.MAddress.split('`')
          physical = this.party.PAddress.split('`')
          break
        case 'notifyparty':
          this.party.MAddress = this.CheckNull(this.party.NotifyPartyId) ? this.party.NotifyPartyId.mailingAddress : ''
          this.party.PAddress = this.CheckNull(this.party.NotifyPartyId) ? this.party.NotifyPartyId.physicalAddress : ''

          mailing = this.party.MAddress.split('`')
          physical = this.party.PAddress.split('`')
          break
      }
      this.$swal({
        title: 'View Address',
        html: `
    <table border class="w-full">
      <tr>
        <td colspan="4"><i><b>Mailing Address:</b></i></td>
      </tr>
      <tr>
        <td colspan="2"><u>Address 1:</u></td>
        <td colspan="2">${mailing[0]}</td>
      </tr>
      <tr>
        <td colspan="2"><u>Address 2:</u></td>
        <td colspan="2">${mailing[1]}</td>
      </tr>
      <tr>
        <td><u>City:</u></td>
        <td>${mailing[2]}</td>
        <td><u>State:</u></td>
        <td>${mailing[3]}</td>
      </tr>
      <tr>
        <td><u>Contury:</u></td>
        <td>${mailing[4]}</td>
        <td><u>Zip:</u></td>
        <td>${mailing[5]}</td>
      </tr>
      <tr>
        <td colspan="4"><i><b>Physical Address:</b></i></td>
      </tr>
      <tr>
        <td colspan="2"><u>Address 1:</u></td>
        <td colspan="2">${physical[0]}</td>
      </tr>
      <tr>
        <td colspan="2"><u>Address 2:</u></td>
        <td colspan="2">${physical[1]}</td>
      </tr>
      <tr>
        <td><u>City:</u></td>
        <td>${physical[2]}</td>
        <td><u>State:</u></td>
        <td>${physical[3]}</td>
      </tr>
      <tr>
        <td><u>Contury:</u></td>
        <td>${physical[4]}</td>
        <td><u>Zip:</u></td>
        <td>${physical[5]}</td>
      </tr>
    </table>`,
      })
    },
    UpdateAddParty() {
      this.$refs.addlpartyRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ESPartiesModel = {
            ESId: this.esid,
            Manufacturer: this.CheckNull(this.party.ManufacturerId) ? this.party.ManufacturerId.partyId : '',
            Seller: this.CheckNull(this.party.SellerId) ? this.party.SellerId.partyId : '',
            Buyer: this.CheckNull(this.party.BuyerId) ? this.party.BuyerId.partyId : '',
            Consolidator: this.CheckNull(this.party.ConsolidatorId) ? this.party.ConsolidatorId.partyId : '',
            ContainerStuffing: this.CheckNull(this.party.ContainerStuffingId) ? this.party.ContainerStuffingId.partyId : '',
            BookingParty: this.CheckNull(this.party.BookingPartyId) ? this.party.BookingPartyId.partyId : '',
            NotifyParty: this.CheckNull(this.party.NotifyPartyId) ? this.party.NotifyPartyId.partyId : '',
          }
          axios.put(`/entry/${this.esid}/summary/party`, ESPartiesModel).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Additional Parties have been saved.')
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Additional Parties cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        } else {
          this.DisplayError('Additional Parties cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    onChangeManufacturer(option) {
      if (this.CheckNull(option)) {
        this.party.MAddress = option.mailingAddress
        this.party.PAddress = option.physicalAddress
        this.party.ManufacturerIdentifier = (option.ein === '' ? option.cbpAssigned : option.ein)
      } else {
        this.party.MAddress = ''
        this.party.PAddress = ''
        this.party.ManufacturerIdentifier = ''
      }
    },
    onChangeSeller(option) {
      if (this.CheckNull(option)) {
        this.party.MAddress = option.mailingAddress
        this.party.PAddress = option.physicalAddress
        this.party.SellerIdentifier = (option.ein === '' ? option.cbpAssigned : option.ein)
      } else {
        this.party.MAddress = ''
        this.party.PAddress = ''
        this.party.SellerIdentifier = ''
      }
    },
    onChangeBuyer(option) {
      if (this.CheckNull(option)) {
        this.party.MAddress = option.mailingAddress
        this.party.PAddress = option.physicalAddress
        this.party.BuyerIdentifier = (option.ein === '' ? option.cbpAssigned : option.ein)
      } else {
        this.party.MAddress = ''
        this.party.PAddress = ''
        this.party.BuyerIdentifier = ''
      }
    },
    onChangeConsolidator(option) {
      if (this.CheckNull(option)) {
        this.party.MAddress = option.mailingAddress
        this.party.PAddress = option.physicalAddress
        this.party.ConsolidatorIdentifier = (option.ein === '' ? option.cbpAssigned : option.ein)
      } else {
        this.party.MAddress = ''
        this.party.PAddress = ''
        this.party.ConsolidatorIdentifier = ''
      }
    },
    onChangeContainerStuffing(option) {
      if (this.CheckNull(option)) {
        this.party.MAddress = option.mailingAddress
        this.party.PAddress = option.physicalAddress
        this.party.ContainerStuffingIdentifier = (option.ein === '' ? option.cbpAssigned : option.ein)
      } else {
        this.party.MAddress = ''
        this.party.PAddress = ''
        this.party.ContainerStuffingIdentifier = ''
      }
    },
    onChangeBookingParty(option) {
      if (this.CheckNull(option)) {
        this.party.MAddress = option.mailingAddress
        this.party.PAddress = option.physicalAddress
        this.party.BookingPartyIdentifier = (option.ein === '' ? option.cbpAssigned : option.ein)
      } else {
        this.party.MAddress = ''
        this.party.PAddress = ''
        this.party.BookingPartyIdentifier = ''
      }
    },
    onChangeNotifyParty(option) {
      if (this.CheckNull(option)) {
        this.party.MAddress = option.mailingAddress
        this.party.PAddress = option.physicalAddress
        this.party.NotifyPartyIdentifier = (option.ein === '' ? option.cbpAssigned : option.ein)
      } else {
        this.party.MAddress = ''
        this.party.PAddress = ''
        this.party.NotifyPartyIdentifier = ''
      }
    },
  },
}

</script>
