<template>
  <div>
    <b-card title="CBP Actions">
      <b-button-toolbar justify>
        <div>
          <b-button
            v-if="!es.InACEDelete"
            v-b-tooltip.hover="'Delete in CBP'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mb-1"
            @click="SendDeleteES()"
          ><feather-icon
             icon="Trash2Icon"
             class="mr-50"
           />
            <span class="align-middle">Remove Entry</span></b-button>
        </div>
        <div>
          <b-button
            v-b-tooltip.hover="'Send Entry Data'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SendES()"
          ><feather-icon
             icon="SendIcon"
             class="mr-50"
           />
            <span class="align-middle">File Entry</span></b-button>
        </div>
      </b-button-toolbar>
    </b-card>
    <b-card>
      <b-button-toolbar justify>
        <div>
          <h4>{{ es.FilerCode }}-{{ es.EntryNumber }}</h4>
          <h6>Status: {{ es.Status }}</h6>
        </div>
        <div>
          <b-button
            v-if="es.InACE !== 'Pending' && es.InACE !== 'Yes' "
            v-b-tooltip.hover="'Delete in Database (Does not update in CBP system)'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon mb-1"
            @click="DeleteES()"
          >
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              size="lg"
            />
          </b-button>
        </div>
      </b-button-toolbar>
      <b-row>
        <b-col md="12">
          <b-tabs lazy>
            <b-tab
              ref="HeaderTab"
              title="Header"
              @click="UpdateComponents()"
            >
              <ESDetailHeader
                :key="index"
                :esid="esid"
                @getFromDetail="GetEntry"
              />
            </b-tab>
            <b-tab
              ref="DatesTab"
              title="Dates"
              @click="UpdateComponents()"
            >
              <ESDetailDates
                :key="index"
                :esid="esid"
                :inace="es.InACE"
              />
            </b-tab>
            <b-tab
              ref="TranspTab"
              title="Transp"
              @click="UpdateComponents()"
            >
              <ESDetailTransp
                :key="index"
                :esid="esid"
                :inace="es.InACE"
                :mot="es.MOT"
                :et="es.ET"
              />
            </b-tab>
            <b-tab
              ref="AddlPartiesTab"
              title="Addl Parties"
              @click="UpdateComponents()"
            >
              <ESDetailAddlParty
                v-if="es.CustomerId"
                :key="index"
                :esid="esid"
                :inace="es.InACE"
                :mot="es.MOT"
                :et="es.ET"
                :customerid="es.CustomerId"
              />
            </b-tab>
            <b-tab
              ref="LineTab"
              title="Lines"
              @click="UpdateComponents()"
            >
              <ESDetailLines
                :key="index"
                :esid="esid"
                :inace="es.InACE"
                :entryaction="es.EntryAction"
              />
            </b-tab>
            <b-tab
              ref="AddlOptionsTab"
              title="Addl Options"
              @click="UpdateComponents()"
            >
              <ESDetailAddlOptions
                :key="index"
                :esid="esid"
                :inace="es.InACE"
                :entryaction="es.EntryAction"
              />
            </b-tab>
            <b-tab
              ref="DocumentsTab"
              title="Docs"
            />
            <b-tab
              ref="DutyTab"
              title="Duty/Fees"
              @click="UpdateComponents()"
            >
              <ESDetailFees
                :key="index"
                :esid="esid"
                :inace="es.InACE"
                :mot="es.MOT"
                :et="es.ET"
              />
            </b-tab>
            <b-tab
              ref="TimelineTab"
              title="Timeline"
              @click="UpdateComponents()"
            >
              <ESDetailTimeline
                :key="index"
                :esid="esid"
                :inace="es.InACE"
                :mot="es.MOT"
                :et="es.ET"
              />
            </b-tab>
            <b-tab
              ref="NotificationTab"
              title="Notifications"
              @click="UpdateComponents()"
            >
              <b-row>
                <b-col md="12">
                  <b-tabs lazy>
                    <b-tab
                      title="Notification"
                      @click="UpdateComponents()"
                    >
                      <ESNotification
                        :key="index"
                        :esid="esid"
                      />
                    </b-tab>
                    <b-tab
                      title="Response"
                      @click="UpdateComponents()"
                    >
                      <ESResponse
                        :key="index"
                        :esid="esid"
                      />
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>

</template>

<script>
import axios from '@axios'
import ESDetailHeader from './ESDetailHeader.vue'
import ESDetailDates from './ESDetailDates.vue'
import ESDetailLines from './ESDetailLine.vue'
import ESDetailFees from './ESDetailFees.vue'
import ESDetailAddlParty from './ESDetailAddlParty.vue'
import ESDetailTransp from './ESDetailTransp.vue'
import ESDetailTimeline from './ESDetailTimeline.vue'
import ESResponse from './ESResponse.vue'
import ESNotification from './ESNotification.vue'
import ESDetailAddlOptions from './ESDetailAddlOptions.vue'

export default {
  components: {
    ESDetailHeader,
    ESDetailDates,
    ESDetailLines,
    ESDetailFees,
    ESDetailAddlParty,
    ESDetailTransp,
    ESDetailTimeline,
    ESNotification,
    ESResponse,
    ESDetailAddlOptions,
  },
  data() {
    return {
      esid: this.$route.params.esId,
      index: 0,
      es: {
        CustomerId: null,
        FilerCode: '',
        EntryNumber: '',
        Revision: '',
        InACE: '',
        IsActive: '',
        ET: '',
        MOT: '',
        EntryAction: '',
        Status: '',
        InACEDelete: false,
      },

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.GetEntry()
    this.LoadingEnd()
  },
  created() {
  },
  methods: {
    GetEntry() {
      return axios.get(`/entry/${this.esid}/summary/header`)
        .then(resp => {
          const response = resp.data
          const respdata = response[0]
          this.es.CustomerId = respdata.customerId
          this.es.Revision = respdata.revision
          this.es.InACE = respdata.inACE
          this.es.IsActive = respdata.active
          this.es.EntryAction = respdata.entryAction
          this.es.FilerCode = respdata.entryFiler
          this.es.EntryNumber = respdata.entryNumberWithCheckdigit
          this.es.ET = respdata.entryType
          this.es.MOT = respdata.mot
          this.es.Status = respdata.status
          this.es.InACEDelete = respdata.inACEDelete
        })
    },
    UpdateComponents() {
      this.GetEntry()
      this.index += 1
    },
    SendES() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Send it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.post(`/entry/${this.esid}/ace/send`).then(() => {
            this.LoadingEnd()
            this.es.Status = 'Pending'
            this.es.InACE = 'Pending'
            this.DisplaySuccess('Entry Summary has been Sent.')
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Cannot send entry summary.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    DeleteES() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/entry/${this.esid}/delete`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Entry Summary has been removed.')
              .then(() => {
                // redirect
                this.$router.push('/abi/es')
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove entry summary.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    SendDeleteES() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.post(`/entry/${this.esid}/ace/delete`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Entry Summary has been removed from CBP.')
              .then(() => {
                // redirect
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove entry summary.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
  },
}
</script>
