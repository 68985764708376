<template>
  <div>
    <b-row>
      <b-col
        md="4"
        offset-md="8"
      >
        <b-button-toolbar justify>
          <div />
          <div>
            <b-button
              v-b-tooltip.hover="'Refresh notification'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mb-1"
              @click="LoadResponse()"
            >
              <font-awesome-icon
                :icon="['fas', 'sync']"
                size="lg"
              />
            </b-button>
          </div>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-table
          v-if="ResponseTable"
          :fields="fields"
          :items="ResponseTable"
          responsive=""
          hover
        >
          <template #cell(Disposition)="data">
            {{ data.item.dispositionDescription + ' (' + data.item.dispositionCode + ')' }}
          </template>
          <template #cell(Reason)="data">
            {{ data.item.reason + ' (' + data.item.reasonCode + ')' }}
          </template>
          <template #cell(StatusDate)="data">
            {{ data.item.addedDate }}
          </template>
          <template #cell(RequestType)="data">
            {{ data.item.sourceOfRequest }}
          </template>
          <template #cell(CBP)="data">
            <ul>
              <li>
                {{ 'Team: ' + data.item.cbpTeam }}
              </li>
              <li>
                {{ 'User: ' + data.item.cbpUser }}
              </li>
              <li>
                {{ 'Tel: ' + data.item.cbpTel + ' Ext: ' + data.item.cbpTelExt }}
              </li>
              <li>
                {{ 'Remarks: ' + data.item.cbpRemarks }}
              </li>
            </ul>
          </template>
          <template #cell(Quotas)="data">
            <b-table
              :fields="quotasfields"
              :items="data.item.quotas"
            >
              <template #cell(Line)="quotas">
                {{ quotas.item.lineItemId }}
              </template>
              <template #cell(Status)="quotas">
                {{ quotas.item.statusDescription + ' (' + quotas.item.stausCode + ')' }}
              </template>
              <template #cell(Quota)="quotas">
                {{ quotas.item.quotaQty + '(' + quotas.item.quotaUOM + ')' }}
              </template>
              <template #cell(Reserved)="quotas">
                {{ quotas.item.reservedQuotaQty + '(' + quotas.item.reservedQuotaUOM + ')' }}
              </template>
            </b-table>
          </template>
          <template #cell(PGAs)="data">
            <b-table
              :fields="pgafields"
              :items="data.item.pgAs"
            >
              <template #cell(Agency)="pga">
                {{ pga.item.agencyCode + '/' + pga.item.agencProgramCode }}
              </template>
              <template #cell(pgaStatus)="pga">
                <ul>
                  <li>{{ 'Entry Level: ' + pga.item.entryLevelStatusMessage + '(' + pga.item.entryLevelStatusCode + ')' }}</li>
                  <li>{{ 'Line Level: ' + pga.item.lineLevelStatusMessage + '(' + pga.item.lineLevelStatusCode + ')' }}</li>
                </ul>
              </template>
              <template #cell(Comments)="pga">
                {{ pga.item.comments }}
              </template>
              <template #cell(Docs)="pga">
                {{ pga.item.docType + '(' + pga.item.docTypeCode + ')' }}
              </template>
              <template #cell(Reviews)="pga">
                <ul
                  v-for="(review, m) in pga.item.reviews"
                  :key="m"
                >
                  <li>
                    {{ review.referenceNumberQ + ': ' + review.referenceNumber }}
                  </li>
                  <li>
                    {{ 'Date: ' + review.receiptDate }}
                  </li>
                  <li
                    v-for="(reason, n) in review.reasonList"
                    :key="n"
                  >
                    {{ reason }}
                  </li>
                </ul>
              </template>
            </b-table>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  name: 'EsResponse',
  props: {
    esid: { type: String, default: '' },
  },
  data() {
    return {
      fields: [
        { key: 'Disposition', lable: 'Disposition' },
        { key: 'Reason', lable: 'Reason' },
        { key: 'StatusDate', lable: 'Status Date' },
        { key: 'RequestType', lable: 'Request Type' },
        { key: 'CBP', lable: 'CBP' },
        { key: 'Quotas', lable: 'Quotas' },
        { key: 'PGAs', lable: 'PGAs' },
      ],
      quotasfields: [
        { key: 'Line', lable: 'Line' },
        { key: 'Status', lable: 'Status' },
        { key: 'Quota', lable: 'Quota' },
        { key: 'Reserved', lable: 'Reserved' },
      ],
      pgafields: [
        { key: 'Agency', lable: 'Agency' },
        { key: 'pgaStatus', lable: 'Status' },
        { key: 'Comments', lable: 'Comments' },
        { key: 'Docs', lable: 'Docs' },
        { key: 'Reviews', lable: 'Reviews' },
      ],
      ResponseTable: [],
      color: '',

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      return Promise.all([this.LoadingStart(), this.LoadResponse()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadResponse() {
      return axios.get(`/entry/${this.esid}/summary/notification`)
        .then(resp => {
          this.ResponseTable = resp.data
        })
    },
  },
}
</script>
