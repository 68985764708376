<template>
  <div>
    <b-card>
      <AgGrid
        ref="ESDetailLineGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-if="inace !== 'Pending' "
            v-b-tooltip.hover="'Add a new line'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="CreateNewLine()"
          >
            <font-awesome-icon
              :icon="['far', 'plus-square']"
              size="lg"
            />
          </b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreateLineModal"
      ref="CreateLine"
      title="New Line"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="headerRules"
        tag="form"
      >
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Invoice #"
                rules="required|max:17"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Invoice #:</b></i></label>
                  <b-form-input
                    id="NewInvoiceNumber"
                    v-model="line.InvoiceNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Invoice Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Invoice Range Begin"
                rules="max:4"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Invoice Range Begin:</b></i></label>
                  <b-form-input
                    id="NewInvRangeB"
                    v-model="line.InvRangeB"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Begin"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Invoice Range End"
                rules="max:4"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Invoice Range End:</b></i></label>
                  <b-form-input
                    id="NewInvRangeE"
                    v-model="line.InvRangeE"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="End"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Part Number"
                rules="max:64"
              >
                <label><i><b>Part Number:</b></i></label>
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="NewPartNumber"
                    v-model="line.PartNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Part Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Article Set"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Article Set:</b></i></label>
                  <v-select
                    id="NewArticleSet"
                    v-model="line.ArticleSet"
                    :options="ArticleSetOptions"
                    label="text"
                    placeholder="Select the option"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Goods Description"
                rules="required|max:64"
              >
                <label class="required"><i><b>Goods Description:</b></i></label>
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="NewDescription"
                    v-model="line.Description"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Goods Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Country of Origin"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Country of Origin:</b></i></label>
                  <v-select
                    id="NewCOO"
                    v-model="line.COO"
                    :options="CountryOptions"
                    label="country"
                    placeholder="Select the country"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Export Country:"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Export Country:</b></i></label>
                  <v-select
                    id="NewCOE"
                    v-model="line.COE"
                    :options="CountryOptions"
                    label="country"
                    placeholder="Select the country"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Port of Loading"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Port of Loading (Foreign):</b></i></label>
                  <v-select
                    id="NewPOL"
                    v-model="line.POL"
                    :options="ForeignPortsOptions"
                    label="foreignPOLName"
                    placeholder="Select a port"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="MID"
                rules="required|max:15"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>MID #:</b></i></label>
                  <b-form-input
                    id="NewMID"
                    v-model="line.MID"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="MID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <label class="required"><i><b>Related Manufacturer:</b></i></label>
            <b-form-checkbox v-model="line.ManufactureRelated" />
          </b-col>

        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ruling Type"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Ruling Type:</b></i></label>
                  <v-select
                    id="NewRulingType"
                    v-model="line.RulingType"
                    :options="RulingOptions"
                    label="text"
                    placeholder="Select a type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ruling Number"
                :rules="`${line.RulingType && (line.RulingType.value !== '') ? 'required|max:6' : 'max:6'}`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Ruling #:</b></i></label>
                  <b-form-input
                    id="NewRulingNumber"
                    v-model="line.RulingNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Ruling Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewLine()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABILineViewDelBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  name: 'EsLine',
  components: {
    AgGrid,
  },
  props: {
    esid: { type: String, default: '' },
    inace: { type: String, default: '' },
    entryaction: { type: String, default: '' },
  },
  data() {
    return {
      required,
      TempLineId: '',
      aggrid: [],
      ProductClaimOptions: [
        { text: 'Craft Beverage Modernization Act Refund Claim (C)', value: 'C' },
        { text: 'A folklore product (F)', value: 'F' },
        { text: 'A made to measure suit (G)', value: 'G' },
        { text: 'Certain garments in chapter 61 or 62 (H)', value: 'H' },
        { text: 'A textile fashion sample (M)', value: 'M' },
      ],
      ArticleSetOptions: [
        { text: 'Header Article (X)', value: 'X' },
        { text: 'Component Article (V)', value: 'V' },
      ],
      RulingOptions: [
        { text: 'Pre-Classification Number (C)', value: 'C' },
        { text: 'Pre-Approval number (P)', value: 'P' },
        { text: 'Binding Ruling number (R)', value: 'R' },
      ],
      SPIOptions: [
        { text: 'GSP (A)', value: 'A' },
        { text: 'Australia Free Trade Agreement (AU)', value: 'AU' },
        { text: 'Automotive Product Trade Act (B)', value: 'B' },
        { text: 'Automotive Product Trade Act + FTA MPF Exemption (B#)', value: 'B#' },
        { text: 'Bahrain Free Trade Agreement (BH)', value: 'BH' },
        { text: 'Agreement on Trade in Civil Aircraft (C)', value: 'C' },
        { text: 'Agreement on Trade in Civil Aircraft + FTA MPF Exemption (C#)', value: 'C#' },
        { text: 'NAFTA-Canada (CA)', value: 'CA' },
        { text: 'Chile Free Trade Act (CL)', value: 'CL' },
        { text: 'Columbia Trade Promotion Agreement (CO)', value: 'CO' },
        { text: 'AGOA (D)', value: 'D' },
        { text: 'CBERA/CBI (E)', value: 'E' },
        { text: 'U.S. – Israel Free Trade Area Agreement (IL)', value: 'IL' },
        { text: 'ATPA (J)', value: 'J' },
        { text: 'ATPDEA (J+)', value: 'J+' },
        { text: 'Jordan Free Trade Agreement (JO)', value: 'JO' },
        { text: 'U.S. – Japan Trade Agreement (JP)', value: 'JP' },
        { text: 'Agreement on Trade in Pharmaceutical Products (K)', value: 'K' },
        { text: 'Agreement on Trade in Pharmaceutical Products + FTA MPF Exemption (K#)', value: 'K#' },
        { text: 'U.S. – Korea Free Trade Agreement (KR)', value: 'KR' },
        { text: 'Uruguay Round Concessions on Intermediate Chemicals for Dyes (L)', value: 'L' },
        { text: 'Uruguay Round concessions on Intermediate Chemicals for Dyes + FTA MPF Exemption (L#)', value: 'L#' },
        { text: 'Morocco Free Trade Agreement (MA)', value: 'MA' },
        { text: 'NAFTA-Mexico (MX)', value: 'MX' },
        { text: 'Wetbank/GZ/Jordan/Israel (N)', value: 'N' },
        { text: 'Nepal Preference Program (NP)', value: 'NP' },
        { text: 'Oman Free Trade Agreement (OM)', value: 'OM' },
        { text: 'Originating CAFTA (P)', value: 'P' },
        { text: 'Panama Trade Promotion Agreement (PA)', value: 'PA' },
        { text: 'Peru Trade Promotion Agreement (PE)', value: 'PE' },
        { text: 'Qualifying CAFTA (P+)', value: 'P+' },
        { text: 'CBTPA (R)', value: 'R' },
        { text: 'USMCA (S)', value: 'S' },
        { text: 'Qualifiying USMCA (A)', value: 'S+' },
        { text: 'Singapore Free Trade Act (SG)', value: 'SG' },
        { text: 'Product of PR or CBERA (W)', value: 'W' },
        { text: 'U.S. Insular Possession (Y)', value: 'Y' },
        { text: 'Freely Associated States (Z)', value: 'Z' },
      ],
      LineUserFeeOptions: [
        { text: 'Beef Fee (053)', value: '053' },
        { text: 'Pork Fee (054)', value: '054' },
        { text: 'Honey Fee (055)', value: '055' },
        { text: 'Cotton Fee (056)', value: '056' },
        { text: 'Raspberry Fee (057)', value: '057' },
        { text: 'Sugar Fee (079)', value: '079' },
        { text: 'Potato Fee (090)', value: '090' },
        { text: 'Lime Fee (102)', value: '102' },
        { text: 'Mushroom Fee (103)', value: '103' },
        { text: 'Watermelon Fee (104)', value: '104' },
        { text: 'Softwood Lumber Fee (105)', value: '105' },
        { text: 'Blueberry Fee (106)', value: '106' },
        { text: 'Avocado Fee (107)', value: '107' },
        { text: 'Mango Fee (108)', value: '108' },
        { text: 'Sorghum Fee (109)', value: '109' },
        { text: 'Dairy Product Fee (110)', value: '110' },
        { text: 'Formal MPF (499)', value: '499' },
        { text: 'HMF (501)', value: '501' },
      ],
      CWOverrideOptions: [
        { text: 'Exception to Embargo (01)', value: '01' },
        { text: 'Timing of Embargo (02)', value: '02' },
        { text: 'Country Verified as Correct (03)', value: '03' },
        { text: 'Prototype (04)', value: '04' },
        { text: 'Sample (05)', value: '05' },
        { text: 'Assist (06)', value: '06' },
        { text: 'One-of-a-kind (07)', value: '07' },
        { text: 'Precision Made (08)', value: '08' },
        { text: 'Unique Material (09)', value: '09' },
        { text: 'Experimental Drug (10)', value: '10' },
        { text: 'Military Application (11)', value: '11' },
        { text: 'Mass Produced (12)', value: '12' },
        { text: 'Less Than Perfect (13)', value: '13' },
        { text: 'Lower Quality Material (14)', value: '14' },
        { text: 'Market Conditions (15)', value: '15' },
        { text: 'Special Handling Required (16)', value: '16' },
        { text: 'Chartered Transportation (17)', value: '17' },
        { text: 'Insurance Costs Very High (18)', value: '18' },
        { text: 'Rush Delivery (19)', value: '19' },
        { text: 'Weight of Article Heavier Than Normal (20)', value: '20' },
        { text: 'Weight of Article Lighter Than Normal (21)', value: '21' },
        { text: 'Packaging Heavier Than Normal (22)', value: '22' },
        { text: 'Packaging Lighter Than Normal (23)', value: '23' },
        { text: 'Non-product Line Item Needed to Conduct Business (24)', value: '24' },
        { text: 'Beginning to Import New Product Line (25)', value: '25' },
        { text: 'Country of Export Verified as Correct (26)', value: '26' },
        { text: 'FTZ Withdrawal Low Foreign Value (27)', value: '27' },
        { text: 'Parameter Change Request Pending (49)', value: '49' },
        { text: 'Correct as Entered (50)', value: '50' },
        { text: 'Entered Under Special Conditions (51)', value: '51' },
      ],
      CWOptions: [
        { text: 'IMPROBABLE COUNTRY (27A)', value: '27A' },
        { text: 'MAXIMUM VALUE EXCEEDED (27P)', value: '27P' },
        { text: 'QTY1/QTY2 (27B)', value: '27B' },
        { text: 'QTY2/QTY1 (27M)', value: '27M' },
        { text: 'OR-HI VAL/QTY (1) (27D)', value: '27D' },
        { text: 'OR-HI VAL/QTY (2) (27F)', value: '27F' },
        { text: 'OR-LO VAL/QTY (1) (27C)', value: '27C' },
        { text: 'OR-LO VAL/QTY (2) (28E)', value: '28E' },
        { text: 'IMPROBABLE AIR TARIFF (27G)', value: '27G' },
        { text: 'GROSS WEIGHT - VESSEL (27I)', value: '27I' },
        { text: 'GROSS WEIGHT - AIR (27H)', value: '27H' },
        { text: 'MAXIMUM CHARGES EXCEEDED (27Q)', value: '27Q' },
        { text: 'OR-AGR CHARGES/VALUE (27J)', value: '27J' },
      ],
      FTZOptions: [
        { text: 'Privileged Foreign (P)', value: 'P' },
        { text: 'Non-Privileged Foreign (N)', value: 'N' },
        { text: 'Domestic (D)', value: 'D' },
      ],
      LicensesOptions: [
        { text: 'Steel Import License (01)', value: '01' },
        { text: 'Singapore TPL Certificate (02)', value: '02' },
        { text: 'Canadian NAFTA TPL Certificate (03)', value: '03' },
        { text: 'Mexican NAFTA TPL Certificate (04)', value: '04' },
        { text: 'Beef Export Certificate (05)', value: '05' },
        { text: 'Diamond Certificate (06)', value: '06' },
        { text: 'ATPDEA Certificate (07)', value: '07' },
        { text: 'Australia Free Trade Export Certificate (08)', value: '08' },
        { text: 'Mexican Cement Import License (09)', value: '09' },
        { text: 'CAFTA TPL Certificate (10)', value: '10' },
        { text: 'ALB Certificate (11)', value: '11' },
        { text: 'Cotton Shirting Fabric License (12)', value: '12' },
        { text: 'Haiti Earned Import Allowance (13)', value: '13' },
        { text: 'Agricultural License (14)', value: '14' },
        { text: 'Canadian Export Sugar Certificate (16)', value: '16' },
        { text: 'Wool License (17)', value: '17' },
        { text: 'CBTPA Certification (18)', value: '18' },
        { text: 'AGOA Textile Provision Number (19)', value: '19' },
        { text: 'USDA Sugar Certificate (21)', value: '21' },
        { text: 'Organic Product Exemption Certificate (22)', value: '22' },
        { text: 'AMS Certificate of Exemption (23)', value: '23' },
        { text: 'Dominican Republic Earned Allowance Program Certificate (25)', value: '25' },
        { text: 'Mexican Sugar Export License (26)', value: '26' },
        { text: 'General Note 15 (c) Waiver Certificate (27)', value: '27' },
        { text: 'Aluminum Import License (28)', value: '28' },
        { text: 'Canadian USMCA TPL Certificate (29)', value: '29' },
        { text: 'Mexican USMCA TPL Certificate (30)', value: '30' },
      ],
      BondCaseOptions: [
        { text: 'Bond (B)', value: 'B' },
        { text: 'Cash (C)', value: 'C' },
      ],
      RateOptions: [
        { text: 'Ad Valorem (A)', value: 'A' },
        { text: 'Specific (S)', value: 'S' },
      ],
      ImporterAdditionalOptions: [
        { text: 'Softwood Lumber Export Information (01)', value: '01' },
        { text: 'Steel products (02)', value: '02' },
        { text: 'Aluminum products (03)', value: '03' },
        { text: 'KRExport Steel Certificate (04)', value: '04' },
      ],
      TaxOptions: [
        { text: 'Distilled Spirits (016)', value: '016' },
        { text: 'Wines (017)', value: '017' },
        { text: 'Tobacco Products (018)', value: '018' },
        { text: 'Other (022)', value: '022' },
      ],
      OtherFeesOptions: [
        { text: 'Coffee Imports (672)', value: '672' },
      ],
      PSCLineOptions: [
        { text: 'Invoice (L01)', value: 'L01' },
        { text: 'SPI (L02)', value: 'L02' },
        { text: 'Classification (L03)', value: 'L03' },
        { text: 'Value (L04)', value: 'L04' },
        { text: 'Non-Dutiable Charges (L05)', value: 'L05' },
        { text: 'Duty (L06)', value: 'L06' },
        { text: 'COO (L07)', value: 'L07' },
        { text: 'COE (L08)', value: 'L08' },
        { text: 'AD/CVD Case-Critical Circumstances (L09)', value: 'L09' },
        { text: 'AD/CVD Case-Party (L10)', value: 'L10' },
        { text: 'AD/CVD Case-Rate (L11)', value: 'L11' },
        { text: 'AD/CVD Case-Scope (L12)', value: 'L12' },
        { text: 'AD/CVD Case-Non-Reimbursement Statement (L13)', value: 'L13' },
        { text: 'Ruling (L14)', value: 'L14' },
        { text: 'PGA-FCC (L15)', value: 'L15' },
        { text: 'Tax (L16)', value: 'L16' },
        { text: 'MPF (L17)', value: 'L17' },
        { text: 'Fee-Other (L18)', value: 'L18' },
        { text: 'Manufacturer (L19)', value: 'L19' },
        { text: 'Foreign Exporter (L20)', value: 'L20' },
        { text: 'Sold to Party (L21)', value: 'L21' },
        { text: 'Delivered To Party (L22)', value: 'L22' },
        { text: 'Textile Category (L23)', value: 'L23' },
        { text: 'Standard Visa (L24)', value: 'L24' },
        { text: 'Textile Export Date. (L25)', value: 'L25' },
        { text: 'License/Certificate/Permit (L26)', value: 'L26' },
        { text: 'Softwood Lumber Export Price (L27)', value: 'L27' },
        { text: 'Softwood Lumber Export Charges (L28)', value: 'L28' },
        { text: 'Quantity Change (L29)', value: 'L29' },
        { text: 'Relationship Change (L30)', value: 'L30' },
        { text: 'Shipping Weight (L31)', value: 'L31' },
      ],
      CountryOptions: [],
      ForeignPortsOptions: [],
      UOMOptions: [],
      line: {
        InvoiceNumber: '',
        InvRangeB: '',
        InvRangeE: '',
        PartNumber: '',
        Description: '',
        COO: '',
        COE: '',
        POL: '',
        MID: '',
        ManufactureRelated: false,
        RulingType: '',
        RulingNumber: '',
        HTS: '',
        Qty: 0.00,
        UOM: '',
        SpecialProgram: '',
        Qty1: 0.00,
        UOM1: '',
        Qty2: 0.00,
        UOM2: '',
        LicenseType: '',
        License: '',
        EnteredValue: 0.00,
        GrossWeight: 0.00,
        FTZStatus: '',
        FTZDate: null,
        FTZQty: 0.00,
        Duty: 0.00,
        NDC: 0.00,
        MMV: 0.00,
        Value: 0.00,
        ADCaseNumber: '',
        ADBondOrCash: '',
        ADDepositRate: 0.00,
        ADRateType: '',
        ADQty: 0.00,
        ADDuty: 0.00,
        ADNonReimburseDecl: '',
        CVDCaseNumber: '',
        CVDBondOrCash: '',
        CVDDepositRate: 0.00,
        CVDRateType: '',
        CVDQty: 0.00,
        CVDDuty: 0.00,
        CVDNonReimburseDecl: '',
        ADCVD: '',
        VISANumber: '',
        TaxCode: '',
        TaxAmount: 0.00,
        OtherRevCode: '',
        OtherRevAmount: 0.00,
        MPF: 0.00,
        HMF: 0.00,
        OtherFeeCode: '',
        OtherFee: 0.00,
        OtherFeeCode1: '',
        OtherFee1: 0.00,
        OtherFeeCode2: '',
        OtherFee2: 0.00,
        PSC1: '',
        PSC2: '',
        PSC3: '',
        PSC4: '',
        PSC5: '',
        CWConditionCode: '',
        CWOverride: '',
        CWConditionCode1: '',
        CWOverride1: '',
        CWConditionCode2: '',
        CWOverride2: '',
        CWConditionCode3: '',
        CWOverride3: '',
        CWConditionCode4: '',
        CWOverride4: '',
        ArticleSet: '',
        IsNAFTANetCost: false,
        ProductClaimCode: '',
        TextileCateCode: '',
      },

    }
  },
  computed: {
    totalDuty() {
      return parseFloat(this.line.Duty) + parseFloat(this.line.ADDuty) + parseFloat(this.line.CVDDuty)
    },
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ESDetailLine')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'lineId',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      { headerName: 'Invoice #', field: 'invoiceNumber', minWidth: 100 },
      { headerName: 'Part #', field: 'partNumber', minWidth: 100 },
      { headerName: 'Qty', field: 'qty', minWidth: 100 },
      { headerName: 'UOM', field: 'uom', minWidth: 100 },
      { headerName: 'Entered Value', field: 'enteredValue', minWidth: 100 },
      { headerName: 'Duty', field: 'duty', minWidth: 100 },
      { headerName: 'MPF', field: 'mpf', minWidth: 100 },
      { headerName: 'HMF', field: 'hmf', minWidth: 100 },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ESDetailLineGrid.saveState('ESDetailLine') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ESDetailLineGrid.saveState('ESDetailLine') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ESDetailLineGrid.saveState('ESDetailLine') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ESDetailLineGrid.saveState('ESDetailLine') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(lineId) {
      this.$router.push(`/abi/es/detail/${this.esid}/line/${lineId}`)
    },
    DoubleClickOpenPopup(params) {
      this.$router.push(`/abi/es/detail/${this.esid}/line/${params.data.lineId}`)
    },
    CreateNewLine() {
      Object.assign(this.$data.line, this.$options.data.apply(this))

      this.$refs.CreateLine.show()
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadLines(), this.LoadCountryList(), this.LoadForeignPorts(), this.LoadUOM()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadCountryList() {
      // Loading Country List
      return axios.get('/abi/codes/country')
        .then(rowData => {
          this.CountryOptions = rowData.data
        })
    },
    LoadForeignPorts() {
      return axios.get('/abi/codes/ports/foreign')
        .then(rowData => {
          this.ForeignPortsOptions = rowData.data
        })
    },
    LoadUOM() {
      return axios.get('/abi/codes/uom')
        .then(rowData => {
          this.UOMOptions = rowData.data
        })
    },
    LoadLines() {
      return axios.get(`/entry/${this.esid}/summary/lines/list`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ESDetailLineGrid.ResetColumns()
        })
    },
    SaveNewLine() {
      this.LoadingStart()
      const ESLineModel = {
        InvoiceNumber: this.line.InvoiceNumber,
        InvRangeB: this.line.InvRangeB,
        InvRangeE: this.line.InvRangeE,
        PartNumber: this.line.PartNumber,
        Description: this.line.Description,
        COO: this.line.COO.countryCode,
        COE: this.line.COE.countryCode,
        POL: this.CheckNull(this.line.POL) ? this.line.POL.foreignPOL : '',
        MID: this.line.MID,
        ManufactureRelated: this.line.ManufactureRelated,
        RulingType: this.CheckNull(this.line.RulingType) ? this.line.RulingType.value : '',
        RulingNumber: this.line.RulingNumber,
        SpecialProgram: this.CheckNull(this.line.SpecialProgram) ? this.line.SpecialProgram.value : '',
        LicenseType: this.CheckNull(this.line.LicenseType) ? this.line.LicenseType.value : '',
        License: this.line.License,
        GrossWeight: this.line.GrossWeight,
        FTZStatus: this.CheckNull(this.line.FTZStatus) ? this.line.FTZStatus.value : '',
        FTZDate: new Date(this.line.FTZDate).toJSON().split('T')[0] !== '1970-01-01' && new Date(this.line.FTZDate).toJSON().split('T')[0] !== 'Invalid Date' ? new Date(this.line.FTZDate).toJSON().split('T')[0] : null,
        FTZQty: this.line.FTZQty,
        ADCaseNumber: this.line.ADCaseNumber,
        ADBondOrCash: this.CheckNull(this.line.ADBondOrCash) ? this.line.ADBondOrCash.value : '',
        ADDepositRate: this.line.ADDepositRate,
        ADRateType: this.CheckNull(this.line.ADRateType) ? this.line.ADRateType.value : '',
        ADQty: this.line.ADQty,
        ADDuty: this.line.ADDuty,
        ADNonReimburseDecl: this.line.ADNonReimburseDecl,
        CVDCaseNumber: this.line.CVDCaseNumber,
        CVDBondOrCash: this.CheckNull(this.line.CVDBondOrCash) ? this.line.CVDBondOrCash.value : '',
        CVDDepositRate: this.line.CVDDepositRate,
        CVDRateType: this.CheckNull(this.line.CVDRateType) ? this.line.CVDRateType.value : '',
        CVDQty: this.line.CVDQty,
        CVDDuty: this.line.CVDDuty,
        CVDNonReimburseDecl: this.line.CVDNonReimburseDecl,
        ADCVD: '',
        VISANumber: this.line.VISANumber,
        TaxCode: this.CheckNull(this.line.TaxCode) ? this.line.TaxCode.value : '',
        TaxAmount: this.line.TaxAmount,
        OtherRevCode: this.CheckNull(this.line.OtherRevCode) ? this.line.OtherRevCode.value : '',
        OtherRevAmount: this.line.OtherRevAmount,
        MPF: this.line.MPF,
        HMF: this.line.HMF,
        OtherFeeCode: this.CheckNull(this.line.OtherFeeCode) ? this.line.OtherFeeCode.value : '',
        OtherFee: this.line.OtherFee,
        OtherFeeCode1: this.CheckNull(this.line.OtherFeeCode1) ? this.line.OtherFeeCode1.value : '',
        OtherFee1: this.line.OtherFee1,
        OtherFeeCode2: this.CheckNull(this.line.OtherFeeCode2) ? this.line.OtherFeeCode2.value : '',
        OtherFee2: this.line.OtherFee2,
        ArticleSet: this.CheckNull(this.line.ArticleSet) ? this.line.ArticleSet.value : '',
        IsNAFTANetCost: this.line.IsNAFTANetCost,
        ProductClaimCode: this.CheckNull(this.line.ProductClaimCode) ? this.line.ProductClaimCode.value : '',
        TextileCateCode: this.line.TextileCateCode,
      }
      axios.post(`/entry/${this.esid}/summary/lines`, ESLineModel).then(response => {
        this.LoadingEnd()
        this.$refs.CreateLine.hide()
        this.DisplaySuccess('Line data has been saved.').then(() => {
          this.$router.push(`/abi/es/detail/${this.esid}/line/${response.data}`)
        })
      })
        .catch(e => {
          this.LoadingEnd()
          this.$refs.CreateLine.hide()
          this.DisplayError('New Line cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
        })
    },
    validateHeader() {
      return new Promise((resolve, reject) => {
        this.$refs.headerRules.validate().then(success => {
          if (success) resolve(true)
          else reject()
        })
      })
    },
    Delete(lineId) {
      if (this.inace !== 'Pending') {
        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        }).then(result => {
          if (result.isConfirmed) {
            this.LoadingStart()
            axios.delete(`/entry/${this.esid}/summary/lines/line/${lineId}`).then(() => {
              this.LoadingEnd()
              this.DisplaySuccess('Line has been removed.')
                .then(() => {
                // redirect
                  this.LoadLines()
                })
            }).catch(e => {
              this.LoadingEnd()
              this.DisplayErrorRemove('Cannot remove Line.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
          }
        })
      } else {
        this.DisplayErrorRemove('Cannot delete Line.', 'Please note, you cannot delete lines while CBP is reviewing.')
      }
    },

  },
}
</script>
