var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"additionaloptionRules",attrs:{"tag":"form"}},[_c('b-button-toolbar',{attrs:{"justify":""}},[_c('div'),_c('div',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Update in Database (Does not update in CBP system)'),expression:"'Update in Database (Does not update in CBP system)'",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.UpdateAddlOptions()}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'save'],"size":"lg"}})],1)],1)]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Air Manifest","rules":"max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Air Manifest:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"AirManifest","state":errors.length > 0 ? false:null,"placeholder":"Air Manifest"},model:{value:(_vm.options.AirManifest),callback:function ($$v) {_vm.$set(_vm.options, "AirManifest", $$v)},expression:"options.AirManifest"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"4811 Ref #","rules":"max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("4811 Ref #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"F4811RefNumber","state":errors.length > 0 ? false:null,"placeholder":"4811 Ref #"},model:{value:(_vm.options.F4811RefNumber),callback:function ($$v) {_vm.$set(_vm.options, "F4811RefNumber", $$v)},expression:"options.F4811RefNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"IT Number","rules":"max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("IT #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"ITNumber","state":errors.length > 0 ? false:null,"placeholder":"IT #"},model:{value:(_vm.options.ITNumber),callback:function ($$v) {_vm.$set(_vm.options, "ITNumber", $$v)},expression:"options.ITNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"G.O. #","rules":"max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("G.O. #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"GONumber","state":errors.length > 0 ? false:null,"placeholder":"G.O. #"},model:{value:(_vm.options.GONumber),callback:function ($$v) {_vm.$set(_vm.options, "GONumber", $$v)},expression:"options.GONumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Deferred Tax Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{},[_c('i',[_c('b',[_vm._v("Deferred Tax Code:")])])]),_c('v-select',{attrs:{"id":"DeferredTaxCode","options":_vm.DeferralOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.options.DeferredTaxCode),callback:function ($$v) {_vm.$set(_vm.options, "DeferredTaxCode", $$v)},expression:"options.DeferredTaxCode"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Deferred Tax Rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Deferred Tax Rate:")])])]),_c('b-form-input',{attrs:{"id":"DeferredTaxRate","type":"number","state":errors.length > 0 ? false:null},model:{value:(_vm.options.DeferredTaxRate),callback:function ($$v) {_vm.$set(_vm.options, "DeferredTaxRate", $$v)},expression:"options.DeferredTaxRate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"FTZ","rules":"max:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("FTZ #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"FTZCode","state":errors.length > 0 ? false:null,"placeholder":"FTZ #"},model:{value:(_vm.options.FTZCode),callback:function ($$v) {_vm.$set(_vm.options, "FTZCode", $$v)},expression:"options.FTZCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Split Indicator"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{},[_c('i',[_c('b',[_vm._v("Split Indicator:")])])]),_c('v-select',{attrs:{"id":"SplitIndicator","options":_vm.SplitOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.options.SplitIndicator),callback:function ($$v) {_vm.$set(_vm.options, "SplitIndicator", $$v)},expression:"options.SplitIndicator"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"1"}},[_c('label',[_c('i',[_c('b',[_vm._v("PGA:")])])]),_c('b-form-checkbox',{model:{value:(_vm.options.IsPGA),callback:function ($$v) {_vm.$set(_vm.options, "IsPGA", $$v)},expression:"options.IsPGA"}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',[_c('i',[_c('b',[_vm._v("Consolidated Express:")])])]),_c('b-form-checkbox',{model:{value:(_vm.options.IsConsolidatedExpress),callback:function ($$v) {_vm.$set(_vm.options, "IsConsolidatedExpress", $$v)},expression:"options.IsConsolidatedExpress"}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',[_c('i',[_c('b',[_vm._v("Sec. 321:")])])]),_c('b-form-checkbox',{model:{value:(_vm.options.IsSec321),callback:function ($$v) {_vm.$set(_vm.options, "IsSec321", $$v)},expression:"options.IsSec321"}})],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" AD/CVD Bonds ")])]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Bond Type","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{},[_c('i',[_c('b',[_vm._v("Bond Type:")])])]),_c('v-select',{attrs:{"id":"BondType","options":_vm.BondTypeOptions,"label":"text","placeholder":"Select the bond type"},model:{value:(_vm.options.ADCVDBondType),callback:function ($$v) {_vm.$set(_vm.options, "ADCVDBondType", $$v)},expression:"options.ADCVDBondType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Surety Number","rules":("" + (_vm.options.ADCVDBondType && (_vm.options.ADCVDBondType.value === '8' || _vm.options.ADCVDBondType.value === '9') ? 'max:3|required' : 'max:3'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.options.ADCVDBondType && (_vm.options.ADCVDBondType.value === '8' || _vm.options.ADCVDBondType.value === '9') ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("Surety #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"SuretyNumber","state":errors.length > 0 ? false:null,"placeholder":"Surety #"},model:{value:(_vm.options.ADCVDSurety),callback:function ($$v) {_vm.$set(_vm.options, "ADCVDSurety", $$v)},expression:"options.ADCVDSurety"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"STB Account","rules":("" + (_vm.options.ADCVDBondType && (_vm.options.ADCVDBondType.value === '9') ? 'required|max:10' : 'max:10'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.options.ADCVDBondType && (_vm.options.ADCVDBondType.value === '9') ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("STB Account #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"STBAccountNumber","state":errors.length > 0 ? false:null,"placeholder":"STB Account #"},model:{value:(_vm.options.ADCVDSTBAccount),callback:function ($$v) {_vm.$set(_vm.options, "ADCVDSTBAccount", $$v)},expression:"options.ADCVDSTBAccount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Bond Amount","rules":("" + (_vm.options.ADCVDBondType && (_vm.options.ADCVDBondType.value === '8' || _vm.options.ADCVDBondType.value === '9') ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.options.ADCVDBondType && (_vm.options.ADCVDBondType.value === '8' || _vm.options.ADCVDBondType.value === '9') ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("Bond Amount:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"BondAmount","type":"number","min":"0","max":"999999999","step":"1","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.options.ADCVDSTBAmount),callback:function ($$v) {_vm.$set(_vm.options, "ADCVDSTBAmount", $$v)},expression:"options.ADCVDSTBAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_c('b-form-checkbox',{model:{value:(_vm.options.IsTARecon),callback:function ($$v) {_vm.$set(_vm.options, "IsTARecon", $$v)},expression:"options.IsTARecon"}},[_vm._v(" TA Reconciliation ")])],1)]),(_vm.options.IsTARecon)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Reconciliation Issue Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{},[_c('i',[_c('b',[_vm._v("Reconciliation Issue Code:")])])]),_c('v-select',{attrs:{"id":"ReconType","options":_vm.ReconcilOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.options.ReconType),callback:function ($$v) {_vm.$set(_vm.options, "ReconType", $$v)},expression:"options.ReconType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1205238727)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Missing Doc Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{},[_c('i',[_c('b',[_vm._v("Missing Doc Code 1:")])])]),_c('v-select',{attrs:{"id":"MissingDoc1","options":_vm.MissingDocOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.options.MissingDoc1),callback:function ($$v) {_vm.$set(_vm.options, "MissingDoc1", $$v)},expression:"options.MissingDoc1"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Missing Doc Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{},[_c('i',[_c('b',[_vm._v("Missing Doc Code 2:")])])]),_c('v-select',{attrs:{"id":"MissingDoc2","options":_vm.MissingDocOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.options.MissingDoc2),callback:function ($$v) {_vm.$set(_vm.options, "MissingDoc2", $$v)},expression:"options.MissingDoc2"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_c('b-form-checkbox',{model:{value:(_vm.options.IsPSC),callback:function ($$v) {_vm.$set(_vm.options, "IsPSC", $$v)},expression:"options.IsPSC"}},[_vm._v(" PSC ")])],1)]),(_vm.options.IsPSC)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"PSC Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.options.IsPSC ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("PSC 1:")])])]),_c('v-select',{attrs:{"id":"PSC1","options":_vm.PSCHeaderOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.options.PSC1),callback:function ($$v) {_vm.$set(_vm.options, "PSC1", $$v)},expression:"options.PSC1"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3238486551)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"PSC Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("PSC 2:")])])]),_c('v-select',{attrs:{"id":"PSC2","options":_vm.PSCHeaderOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.options.PSC2),callback:function ($$v) {_vm.$set(_vm.options, "PSC2", $$v)},expression:"options.PSC2"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1184874144)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"PSC Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("PSC 3:")])])]),_c('v-select',{attrs:{"id":"PSC3","options":_vm.PSCHeaderOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.options.PSC3),callback:function ($$v) {_vm.$set(_vm.options, "PSC3", $$v)},expression:"options.PSC3"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3734854977)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"PSC Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("PSC 4:")])])]),_c('v-select',{attrs:{"id":"PSC4","options":_vm.PSCHeaderOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.options.PSC4),callback:function ($$v) {_vm.$set(_vm.options, "PSC4", $$v)},expression:"options.PSC4"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3011111270)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"PSC Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("PSC 5:")])])]),_c('v-select',{attrs:{"id":"PSC5","options":_vm.PSCHeaderOptions,"label":"text","placeholder":"Select the option"},model:{value:(_vm.options.PSC5),callback:function ($$v) {_vm.$set(_vm.options, "PSC5", $$v)},expression:"options.PSC5"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,2752260359)})],1)],1)],1):_vm._e(),(_vm.options.IsPSC)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Contact Name","rules":("" + (_vm.options.IsPSC ? 'required|max:40' : 'max:40'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.options.IsPSC ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("Contact Name:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"PSCContactName","state":errors.length > 0 ? false:null,"placeholder":"Contact Name"},model:{value:(_vm.options.PSCContactName),callback:function ($$v) {_vm.$set(_vm.options, "PSCContactName", $$v)},expression:"options.PSCContactName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3166479049)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Contact Phone","rules":("" + (_vm.options.IsPSC ? 'required|max:15' : 'max:15'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.options.IsPSC ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("Contact Phone:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"PSCContactPhone","state":errors.length > 0 ? false:null,"placeholder":"Contact phone"},model:{value:(_vm.options.PSCContactPhone),callback:function ($$v) {_vm.$set(_vm.options, "PSCContactPhone", $$v)},expression:"options.PSCContactPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4050946889)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"PSC Reason","rules":"max:75"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("PSC Reason:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"PSCReason","state":errors.length > 0 ? false:null,"placeholder":"PSC Reason"},model:{value:(_vm.options.PSCReason),callback:function ($$v) {_vm.$set(_vm.options, "PSCReason", $$v)},expression:"options.PSCReason"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4062824413)})],1)],1)],1):_vm._e(),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_c('b-form-checkbox',{model:{value:(_vm.options.IsConsolidatedES),callback:function ($$v) {_vm.$set(_vm.options, "IsConsolidatedES", $$v)},expression:"options.IsConsolidatedES"}},[_vm._v(" Consolidated Release ")])],1)]),(_vm.options.IsConsolidatedES)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Entry 1","rules":("" + (_vm.options.IsConsolidatedES ? 'required|max:11' : 'max:11'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.options.IsConsolidatedES ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("Entry 1:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"ConsolidatedES","state":errors.length > 0 ? false:null,"placeholder":"Entry #"},model:{value:(_vm.options.ConsolidatedES),callback:function ($$v) {_vm.$set(_vm.options, "ConsolidatedES", $$v)},expression:"options.ConsolidatedES"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2109921510)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Entry 2","rules":"max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Entry 2:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"ConsolidatedES1","state":errors.length > 0 ? false:null,"placeholder":"Entry #"},model:{value:(_vm.options.ConsolidatedES1),callback:function ($$v) {_vm.$set(_vm.options, "ConsolidatedES1", $$v)},expression:"options.ConsolidatedES1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1814996108)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Entry 3","rules":"max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Entry 3:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"ConsolidatedES2","state":errors.length > 0 ? false:null,"placeholder":"Entry #"},model:{value:(_vm.options.ConsolidatedES2),callback:function ($$v) {_vm.$set(_vm.options, "ConsolidatedES2", $$v)},expression:"options.ConsolidatedES2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,141263565)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Entry 4","rules":"max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Entry 4:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"ConsolidatedES3","state":errors.length > 0 ? false:null,"placeholder":"Entry #"},model:{value:(_vm.options.ConsolidatedES3),callback:function ($$v) {_vm.$set(_vm.options, "ConsolidatedES3", $$v)},expression:"options.ConsolidatedES3"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3037985674)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Entry 5","rules":"max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Entry 5:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"ConsolidatedES4","state":errors.length > 0 ? false:null,"placeholder":"Entry #"},model:{value:(_vm.options.ConsolidatedES4),callback:function ($$v) {_vm.$set(_vm.options, "ConsolidatedES4", $$v)},expression:"options.ConsolidatedES4"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3129089227)})],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }