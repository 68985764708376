<template>
  <div>
    <b-row>
      <b-col md="12">
        <validation-observer
          ref="transpRules"
          tag="form"
        >
          <b-button-toolbar justify>
            <div />
            <div>
              <b-button
                v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                @click="UpdateTransp()"
              >
                <font-awesome-icon
                  :icon="['far', 'save']"
                  size="lg"
                />
              </b-button>
            </div>
          </b-button-toolbar>
          <div class="divider my-2">
            <div class="divider-text">
              Ports
            </div>
          </div>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Port of Export"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Port of Export (Foreign):</b></i></label>
                    <v-select
                      id="POX"
                      v-model="Ports.POX"
                      :options="ForeignPortsOptions"
                      label="foreignPOLName"
                      placeholder="Select a port"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Port of Loading"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Port of Loading (Foreign):</b></i></label>
                    <v-select
                      id="POL"
                      v-model="Ports.POL"
                      :options="ForeignPortsOptions"
                      label="foreignPOLName"
                      placeholder="Select a port"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Port of Arrival"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Port of Arrival:</b></i></label>
                    <v-select
                      id="POA"
                      v-model="Ports.POA"
                      :options="USPortOptions"
                      label="portName"
                      placeholder="Select a port"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Port of Unloading"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Port of Unloading:</b></i></label>
                    <v-select
                      id="POU"
                      v-model="Ports.POU"
                      :options="USPortOptions"
                      label="portName"
                      placeholder="Select a port"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Port of Entry"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Port of Entry:</b></i></label>
                    <v-select
                      id="POE"
                      v-model="Ports.POE"
                      :options="USPortOptions"
                      label="portName"
                      placeholder="Select a port"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Port of Delivery"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class=""><i><b>Port of Delivery:</b></i></label>
                    <v-select
                      id="POD"
                      v-model="Ports.POD"
                      :options="USPortOptions"
                      label="portName"
                      placeholder="Select a port"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Exam Site"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Exam Site:</b></i></label>
                    <v-select
                      id="ExamSite"
                      v-model="Ports.ExamSite"
                      :options="USPortOptions"
                      label="portName"
                      placeholder="Select a port"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Remote Exam Site"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Remote Exam Site:</b></i></label>
                    <v-select
                      id="RemoteExamSite"
                      v-model="Ports.RemoteExamSite"
                      :options="USPortOptions"
                      label="portName"
                      placeholder="Select a port"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              Conveyance
            </div>
          </div>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Carrier Code"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Carrier Code:</b></i></label>
                    <v-select
                      id="Carrier Code"
                      v-model="Transp.CarrierCode"
                      :options="CarrierOptions"
                      label="name"
                      placeholder="Select a carrier"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Flight/Voyage"
                  rules="max:10|required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Flight/Voyage:</b></i></label>
                    <b-form-input
                      id="FlightVoyage"
                      v-model="Transp.FlightVoyage"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Flight/Voyage"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Vessel Code"
                  :rules="`${mot === '10' || mot === '11' ? 'max:7|required' : 'max:7'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${mot === '10' || mot === '11' ? 'required' : ''}`"><i><b>Vessel Code:</b></i></label>
                    <b-form-input
                      id="VesselCode"
                      v-model="Transp.VesselCode"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Vessel Code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Vessel Name"
                  :rules="`${mot === '10' || mot === '11' ? 'max:20|required' : 'max:20'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${mot === '10' || mot === '11' ? 'required' : ''}`"><i><b>Vessel Name:</b></i></label>
                    <b-form-input
                      id="Vesselname"
                      v-model="Transp.Vesselname"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Vessel Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Destination"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Destination:</b></i></label>
                    <v-select
                      id="Destination"
                      v-model="Transp.Destination"
                      :options="StateOptions"
                      label="state"
                      placeholder="Select a state"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="FIRMS"
                  rules=""
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>FIRMS:</b></i></label>
                    <v-select
                      id="FIRMS"
                      v-model="Transp.FIRMS"
                      :options="FIRMSOptions"
                      label="firmsName"
                      placeholder="Select a FIRMS"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              BOL
            </div>
          </div>
          <b-row>
            <b-col md="2">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Manifest Issuer"
                  :rules="`${'max:4'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Issuer:</b></i></label>
                    <b-form-input
                      id="ManifestIssuer"
                      v-model="Transp.ManifestIssuer"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Issuer"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Master BOL"
                  :rules="`${'required|max:12'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Master BOL:</b></i></label>
                    <b-form-input
                      id="MBOL"
                      v-model="Transp.MBOL"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Master BOL"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="House BOL"
                  :rules="`${'required|max:12'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>House BOL:</b></i></label>
                    <b-form-input
                      id="HBOL"
                      v-model="Transp.HBOL"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="House BOL"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Sub House BOL"
                  :rules="`${'max:12'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class=""><i><b>Sub House BOL:</b></i></label>
                    <b-form-input
                      id="SubHBOL"
                      v-model="Transp.SubHBOL"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Sub House BOL"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="In Bond/In Transit"
                  :rules="`${'max:12'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class=""><i><b>In Bond/In Transit #:</b></i></label>
                    <b-form-input
                      id="InBondNumber"
                      v-model="Transp.InBondNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="In Bond/In Transit #"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bonded By"
                  :rules="`max:50`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Bonded By:</b></i></label>
                    <b-form-input
                      id="BondedBy"
                      v-model="Transp.BondedBy"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Bonded By"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Manifested Qty"
                  rules="required|min_value:0|max_value:999999999|numeric"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Manifested Qty:</b></i></label>
                    <b-form-input
                      id="ManifestedQty"
                      v-model="Transp.ManifestedQty"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Manifested UOM"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Manifested UOM:</b></i></label>
                    <v-select
                      id="ManifestedUOM"
                      v-model="Transp.ManifestedUOM"
                      :options="UOMOptions"
                      label="codeName"
                      placeholder="Select the uom"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Boarded Qty"
                  rules="required|min_value:0|max_value:999999999|numeric"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Boarded Qty:</b></i></label>
                    <b-form-input
                      id="BoardedQty"
                      v-model="Transp.BoardedQty"
                      type="number"
                      min="0"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Boarded UOM"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Boarded UOM:</b></i></label>
                    <v-select
                      id="BoardedUOM"
                      v-model="Transp.BoardedUOM"
                      :options="UOMOptions"
                      label="codeName"
                      placeholder="Select the uom"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'

export default {
  name: 'EsTransp',
  props: {
    esid: { type: String, default: '' },
    inace: { type: String, default: '' },
    customerid: { type: Object, default: null },
    et: { type: String, default: '' },
    mot: { type: String, default: '' },
  },
  data() {
    return {
      required,
      USPortOptions: [],
      StateOptions: [],
      ForeignPortsOptions: [],
      FIRMSOptions: [],
      CarrierOptions: [],
      UOMOptions: [],
      Ports: {
        POX: '',
        POL: '',
        POA: '',
        POU: '',
        POE: '',
        POD: '',
        ExamSite: '',
        RemoteExamSite: '',
      },
      Transp: {
        FlightVoyage: '',
        FIRMS: '',
        Destination: '',
        CarrierCode: '',
        VesselCode: '',
        Vesselname: '',
        BondedBy: '',
        ManifestIssuer: '',
        MBOL: '',
        HBOL: '',
        SubHBOL: '',
        InBondNumber: '',
        ManifestedQty: '',
        ManifestedUOM: '',
        BoardedQty: '',
        BoardedUOM: '',
      },

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadUSPorts(), this.LoadStates(),
        this.LoadForeignPorts(), this.LoadFIRMS(), this.LoadCarrier(), this.LoadUOM()]).then(() => {
        this.LoadTranspPortsData().then(resp => {
          const response = resp.data
          const respdata = response[0]
          this.Ports.POX = this.ForeignPortsOptions.find(option => option.foreignPOL === respdata.pox)
          this.Ports.POL = this.ForeignPortsOptions.find(option => option.foreignPOL === respdata.pol)
          this.Ports.POA = this.USPortOptions.find(option => option.portCode === respdata.poa)
          this.Ports.POU = this.USPortOptions.find(option => option.portCode === respdata.pou)
          this.Ports.POE = this.USPortOptions.find(option => option.portCode === respdata.poe)
          this.Ports.POD = this.USPortOptions.find(option => option.portCode === respdata.pod)
          this.Ports.ExamSite = this.USPortOptions.find(option => option.portCode === respdata.examSite)
          this.Ports.RemoteExamSite = this.USPortOptions.find(option => option.portCode === respdata.remoteExamSite)

          this.Transp.CarrierCode = this.CarrierOptions.find(option => option.code === respdata.carrierCode)
          this.Transp.FlightVoyage = respdata.flightVoyage
          this.Transp.Destination = this.StateOptions.find(option => option.stateCode === respdata.destination)
          this.Transp.FIRMS = this.FIRMSOptions.find(option => option.firms === respdata.carrierCode)
          this.Transp.VesselCode = respdata.vesselCode
          this.Transp.Vesselname = respdata.vesselname
          this.Transp.BondedBy = respdata.bondedby

          this.Transp.ManifestIssuer = respdata.manifestIssuer
          this.Transp.MBOL = respdata.mbol
          this.Transp.HBOL = respdata.hbol
          this.Transp.SubHBOL = respdata.subHBOL
          this.Transp.InBondNumber = respdata.inBondNumber

          this.Transp.ManifestedQty = respdata.manifestedQty
          this.Transp.ManifestedUOM = this.UOMOptions.find(option => option.code === respdata.manifestedUOM)
          this.Transp.BoardedQty = respdata.boardedQty
          this.Transp.BoardedUOM = this.UOMOptions.find(option => option.code === respdata.boardedUOM)

          this.LoadingEnd()
        })
      })
    },
    LoadUOM() {
      return axios.get('/abi/codes/uom')
        .then(rowData => {
          this.UOMOptions = rowData.data
        })
    },
    LoadUSPorts() {
      return axios.get('/abi/codes/ports/us')
        .then(rowData => {
          this.USPortOptions = rowData.data
        })
    },
    LoadForeignPorts() {
      return axios.get('/abi/codes/ports/foreign')
        .then(rowData => {
          this.ForeignPortsOptions = rowData.data
        })
    },
    LoadFIRMS() {
      return axios.get('/abi/codes/firms')
        .then(rowData => {
          this.FIRMSOptions = rowData.data
        })
    },
    LoadCarrier() {
      return axios.get('/abi/codes/carrier/all')
        .then(rowData => {
          this.CarrierOptions = rowData.data
        })
    },
    LoadStates() {
      return axios.get('/abi/codes/usstate')
        .then(rowData => {
          this.StateOptions = rowData.data
        })
    },
    LoadTranspPortsData() {
      return axios.get(`/entry/${this.esid}/summary/transport`)
    },
    UpdateTransp() {
      this.$refs.transpRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ESPortsTranspModel = {
            POX: this.CheckNull(this.Ports.POX) ? this.Ports.POX.foreignPOL : '',
            POL: this.CheckNull(this.Ports.POL) ? this.Ports.POL.foreignPOL : '',
            POA: this.CheckNull(this.Ports.POA) ? this.Ports.POA.portCode : '',
            POU: this.CheckNull(this.Ports.POU) ? this.Ports.POU.portCode : '',
            POE: this.CheckNull(this.Ports.POE) ? this.Ports.POE.portCode : '',
            POD: this.CheckNull(this.Ports.POD) ? this.Ports.POD.portCode : '',
            ExamSite: this.CheckNull(this.Ports.ExamSite) ? this.Ports.ExamSite.portCode : '',
            RemoteExamSite: this.CheckNull(this.Ports.RemoteExamSite) ? this.Ports.RemoteExamSite.portCode : '',
            FlightVoyage: this.Transp.FlightVoyage,
            FIRMS: this.CheckNull(this.Transp.FIRMS) ? this.Transp.FIRMS.firms : '',
            Destination: this.CheckNull(this.Transp.Destination) ? this.Transp.Destination.stateCode : '',
            CarrierCode: this.CheckNull(this.Transp.CarrierCode) ? this.Transp.CarrierCode.code : '',
            VesselCode: this.Transp.VesselCode,
            Vesselname: this.Transp.Vesselname,
            BondedBy: this.Transp.BondedBy,
            ManifestIssuer: this.Transp.ManifestIssuer,
            MBOL: this.Transp.MBOL,
            HBOL: this.Transp.HBOL,
            SubHBOL: this.Transp.SubHBOL,
            InBondNumber: this.Transp.InBondNumber,
            ManifestedQty: this.Transp.ManifestedQty,
            ManifestedUOM: this.CheckNull(this.Transp.ManifestedUOM) ? this.Transp.ManifestedUOM.code : '',
            BoardedQty: this.Transp.BoardedQty,
            BoardedUOM: this.CheckNull(this.Transp.BoardedUOM) ? this.Transp.BoardedUOM.code : '',

          }
          axios.put(`/entry/${this.esid}/summary/transport`, ESPortsTranspModel).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Transportation data has been saved.')
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Trnasportation data cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        } else {
          this.DisplayError('Trnasportation data cannot be saved.', 'Please check data requirements.')
        }
      })
    },

  },
}

</script>
